import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { EventService, Product, TranslationService, WindowRef } from '@spartacus/core';
import { CurrentProductService, ProductIntroComponent } from '@spartacus/storefront';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { ProductDetailsService } from "../core/facade/product-details.service";
import { defaultIfEmpty, distinctUntilChanged, filter, first, map, startWith, switchMap, take, takeLast, tap } from "rxjs/operators";
import { CurrencyPipe } from "@angular/common";
import { ProductDetailsPrice } from "../core/models/product-details.models";
import { GarageService } from "../../ymm/core/facade/garage.service";
import { ListrakService } from '../../listrak/core/listrak.service';
import { SkuPageService } from '../../sku-landing-page/skupage.service';
import { Title } from "@angular/platform-browser";
import { ProductPriceService } from "../core/facade/product-price.service";
import { ProductConfigurationService } from "../configurator/core/facade/product-configuration.service";
import { variantSelectionChanged } from "../../../../tools/tools";
import { DataLayerService } from 'src/app/spartacus/features/data-layer/data-layer.service';
import { CustomCurrentProductService } from '../current-product.service';

@Component({
  selector: 'cx-product-intro',
  templateUrl: './product-intro.component.html',
  styleUrls: [ './product-intro.component.scss' ],
  providers: [ CurrencyPipe ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomProductIntroComponent extends ProductIntroComponent implements OnInit, OnDestroy {
  variantProduct: any;
  sku:String;
  private subscriptions = new Subscription();
  displayFrom: boolean | undefined;
  protected qaComponentId = 'QATurnToFlexCmsComponent';
  protected qaTranslationKey = `TabPanelContainer.tabs.${ this.qaComponentId }`;
  isSkuPage = this.skuPageService.isSKUPage();
  combinedProductState$: Observable<any>;

  constructor(currentProductService: CurrentProductService,
              translationService: TranslationService,
              winRef: WindowRef,
              eventService: EventService,
              private productDetailsService: ProductDetailsService,
              private garageService: GarageService,
              private listrakService: ListrakService,
              private skuPageService: SkuPageService,
              private productPriceService: ProductPriceService,
              private productConfigService: ProductConfigurationService,
              private title: Title,
              private dataLayerService: DataLayerService,
              private customCurrentProductService: CustomCurrentProductService) {
    super(currentProductService, translationService, winRef, eventService);
  }

  ngOnInit(): void {
    this.combinedProductState$ = this.productDetailsService.combinedProductState$;

    this.subscriptions.add(
      combineLatest([
        this.currentProductService.getProduct()
          .pipe(
            distinctUntilChanged((previous, current) => previous.code === current.code),
            filter(product => !!product?.code)
            
          ),
        this.productDetailsService.getSelectedVariants()
          .pipe(
            filter(v => v?.length > 0),
            distinctUntilChanged(variantSelectionChanged),
          ),
        this.productConfigService.subModelOptionChange$.pipe(distinctUntilChanged()),
        this.garageService.getActiveVehicle(),
      ]).subscribe(
        ([ product, variants, subModelOption, _ ]) => {
          this.productPriceService.updatePrice(product.code, variants, subModelOption);

          // TODO: Use Resolvers to make this.
          if (this.skuPageService.isSKUPage()) {
            this.title.setTitle(product.metaTitle ?? product.name);
            this.trackViewItem(product);
          }
        }
      ));

      // get the actual product
      if (!this.isSkuPage) {
        this.subscriptions.add(
          this.productDetailsService.getSku().pipe(
            filter(sku => sku !== undefined),
            distinctUntilChanged()
          ).subscribe(sku => {
            try {
              this.listrakService.browseAbandomentPDP(sku);
            } catch {}

            this.customCurrentProductService.loadProduct(sku, 'details').pipe(first()).subscribe(product => {
              this.trackViewItem(product);
            })
          })
        )
      }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private trackViewItem(product: any): void {
    let variant = product?.baseOptions[0]?.selected;
    this.dataLayerService.viewItemEvent(product, variant);
  }

  protected override getReviewsComponent(): HTMLElement | null {
    return this.winRef.document.querySelector('.js-product-review-selector');
  }

  private customGetTabByLabel(
    label: string,
    tabsComponent: HTMLElement
  ): HTMLElement | undefined {
    const tabElements: HTMLCollectionOf<HTMLElement> =
      tabsComponent.getElementsByTagName('button');
    return Array.from(tabElements).find((buttonElement) =>
      buttonElement.innerText.includes(label)
    );
  }

  private customClickTabIfInactive(tab: HTMLElement): void {
    if (
      !tab.classList.contains('active') ||
      tab.classList.contains('toggled')
    ) {
      tab.click();
    }
  }

  private customGetTabsComponent(): HTMLElement | null {
    return this.winRef.document.querySelector('cx-tab-paragraph-container');
  }

  showQa() {
    this.translationService
      .translate(this.qaTranslationKey)
      .subscribe((qaTabLabel) => {
        const tabsComponent = this.customGetTabsComponent()
        const qaTab =
          tabsComponent && this.customGetTabByLabel(qaTabLabel, tabsComponent);

        if (qaTab) {
          this.customClickTabIfInactive(qaTab);
          setTimeout(() => {
            qaTab.scrollIntoView({ behavior: 'smooth' });
            qaTab.focus({ preventScroll: true });
          });
        }
      })
      .unsubscribe();
  }
}
