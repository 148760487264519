import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConverterService, HttpParamsURIEncoder, Occ, OccEndpointsService, PaymentDetails } from "@spartacus/core";
import { Observable, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { CURRENT_CART } from "../../../../../tools/utils";
import {
  DP_DETAILS_NORMALIZER
} from "../../../digital-payments/module/checkout/adapters/converters";
import {
  CustomDigitalPaymentsAdapter
} from "../../../digital-payments/module/checkout/adapters/digital-payments.adapter";
import { CustomDpPaymentRequest } from "../../../digital-payments/module/checkout/models";

@Injectable()
export class OccSnappayAdapter implements CustomDigitalPaymentsAdapter {
  private readonly paramEncoder = new HttpParamsURIEncoder();
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService
  ) { }

  getPaymentCCIframe(
    userId: string,
    cartId: string
  ): Observable<string> {
    const url = this.occEndpoints.buildUrl('paymentCCIframeRequest', {
      urlParams: { userId, cartId },
    });

    return this.http
      .get(url, { responseType: 'text' })
      .pipe(
        tap((response) => {
          console.log('API Response:', response);
        }),
        catchError((error) => {
          console.error('Error occurred:', error);
          return of('');
        })
      );
  }

  createPaymentDetails(
    sessionId: string,
    signature: string,
    userId: string,
    cartId = CURRENT_CART
  ): Observable<PaymentDetails> {
    let params = new HttpParams({ encoder: this.paramEncoder });
    params = params.append('sid', sessionId);
    params = params.append('sign', signature);
    const url = this.occEndpoints.buildUrl('paymentDetails', {
      urlParams: { userId, cartId },
    });

    return this.http
      .post<Occ.PaymentDetails>(url, null, { params: params })
      .pipe(this.converter.pipeable(DP_DETAILS_NORMALIZER));
  }

  createCardRegistrationDetails(
    paymentDetails: PaymentDetails,
    userId: string,
    cartId: string = CURRENT_CART
  ): Observable<any> {
    const url = this.occEndpoints.buildUrl('cardRegistration', {
      urlParams: { userId, cartId },
    });

    return this.http
      .post(url, paymentDetails, { responseType: 'json' })
      .pipe(
        tap((response) => {
          console.log('API Response:', response);
        }),
        catchError((error) => {
          console.error('Error occurred:', error);
          return of('');
        })
      );
  }

  createPaymentRequest(userId?: string, cartId?: string): Observable<CustomDpPaymentRequest> {
    return undefined;
  }
}
