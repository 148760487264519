import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CmsService } from '@spartacus/core';
import { BreakpointService, CmsComponentData } from '@spartacus/storefront';
import { Observable, combineLatest } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { breakpointMediaMapKey } from 'src/app/components/utils/cms-utils';
import { environment } from 'src/environments/environment';
import { CmsFamilyCovercraftBanner } from './family-covercraft.model';

@Component({
  selector: 'family-covercraft',
  templateUrl: './family-covercraft.component.html'
})
export class FamilyCovercraftComponent {
  banner!: CmsFamilyCovercraftBanner

  banners$: Observable<any> = this.component.data$.pipe(
    tap((data)=>{
      this.banner = data
    }),
    switchMap(({ components }) => {
      const banners$ = components
        .split(' ')
        .map((entry: string) => this.cmsService.getComponentData<CmsFamilyCovercraftBanner>(entry));
      return combineLatest(banners$);
    }),
  );

  constructor(
    protected component: CmsComponentData<any>,
    private router: Router,
    private cmsService: CmsService
    ){}



  openLink(link:string) {
    this.router.navigate([link])
  }
}
