import { Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ActiveCartFacade, Cart } from '@spartacus/cart/base/root';
import { CheckoutConfigService, CheckoutDeliveryModeComponent, CheckoutStepService } from '@spartacus/checkout/base/components';
import { CheckoutDeliveryModesFacade } from '@spartacus/checkout/base/root';
import {  WindowRef } from '@spartacus/core';
import { Subscription, combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { DataLayerService } from 'src/app/spartacus/features/data-layer/data-layer.service';


@Component({
  selector: 'custom-checkout-delivery-mode',
  templateUrl: './custom-checkout-delivery-mode.component.html',
  styleUrls: ['./custom-checkout-delivery-mode.component.scss']
})

export class CustomCheckoutDeliveryModeComponent extends CheckoutDeliveryModeComponent implements OnInit, OnDestroy {
  @Input() hideButtons: boolean = false;
  @Input() canEdit: boolean = true;
  @Input() onlySelected: boolean = false;
  cart$: Observable<Cart>;
  items: any[] = [];
  modeCode: any ;
  constructor(
    protected override fb: UntypedFormBuilder,
    protected override checkoutConfigService: CheckoutConfigService,
    protected override activatedRoute: ActivatedRoute,
    protected override checkoutStepService: CheckoutStepService,
    protected override checkoutDeliveryModesFacade: CheckoutDeliveryModesFacade,
    protected override activeCartFacade: ActiveCartFacade,
    protected winRef: WindowRef,
    private dataLayerService: DataLayerService
    ,
    
  ){
    super(
       
     
     
     
     
     
     fb, checkoutConfigService, activatedRoute, checkoutStepService, checkoutDeliveryModesFacade, activeCartFacade
    );
  };
  filteredSupportedDeliveryModes$ = combineLatest([
    this.supportedDeliveryModes$,
    this.selectedDeliveryModeCode$
  ]).pipe(
    map(([modes, selectedModeCode]) => this.onlySelected ? modes.filter((mode) => mode.code === selectedModeCode) : modes)
  );

  selectedMode: any;
  selectedCode: string;
  subscriptions = new Subscription;


  ngOnInit(): void {
    this.subscriptions.add(
      this.selectedDeliveryModeCode$.subscribe((code)=> { this.selectedCode = code })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  selectMode(mode: any) {
    this.selectedMode = mode;
    

  }
  override  getAriaChecked(code: string | undefined): boolean {
    if(code === this.mode.controls['deliveryModeId'].value){
      this.modeCode=code;
    }
    return code === this.mode.controls['deliveryModeId'].value;
  }
  override next(): void {
    this.checkoutStepService.next(this.activatedRoute);
    this.trackChooseDeliveryMethod();
  }

  protected trackChooseDeliveryMethod() {
    // Get the active cart as an observable
    this.cart$ = this.activeCartFacade.getActive();

    // Subscribe to the cart observable to access the cart data
    this.cart$.pipe(take(1)).subscribe(cart => {
      this.dataLayerService.addShippingInfoEvent(cart);
    });
  }
}
