import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthService, WindowRef } from '@spartacus/core';
import { LoginComponent } from '@spartacus/user/account/components';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Router } from '@angular/router';
import { closeAccountMenu, toggleAccountMenu } from 'src/app/spartacus/features/user/core/store/user-account.actions';

@Component({
  selector: 'cx-login',
  templateUrl: './login.component.html',
})

export class CustomLoginComponent extends LoginComponent {

  constructor(
    private _auth: AuthService,
    private _userAccount: UserAccountFacade,
    private store: Store,
    private router: Router
  ) {
    super(_auth, _userAccount);
  }

  closeHamburger() {
    const cxHeaderElement = document.getElementById('cx-header');
    const cxHamburgerElement = document.getElementsByClassName('cx-hamburger');
    cxHeaderElement?.classList.remove('is-expanded');
    cxHamburgerElement[0]?.classList.remove('is-active');

    this.toggleMenu();
  }

  toggleMenu () {
    this.store.dispatch(toggleAccountMenu());
  }

  onClick(event: Event, route: string): void {
    event.preventDefault(); 

    this.store.dispatch(closeAccountMenu());

    this.router.navigateByUrl(route);
  }
}
