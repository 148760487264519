import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { CmsService, isNotNullable, Product } from "@spartacus/core";
import { map } from "rxjs/operators";
import { ProductDetailsPrice } from "../core/models/product-details.models";
import { SkuPageService } from "../../sku-landing-page/skupage.service";

@Component({
  selector: 'cx-product-price',
  templateUrl: './product-price.component.html',
  styleUrls: [ './product-price.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ProductPriceComponent implements OnChanges {
  @Input() combinedProductState: { product: Product; price: ProductDetailsPrice } | undefined;

  private _displayFrom: boolean | undefined;
  _activePrice: any; 

  ngOnChanges(): void {
    if (this.combinedProductState) {
      const { product, price } = this.combinedProductState;
  
      this._displayFrom =
        price?.prices?.displayPriceFrom ||
        (product.displayPriceFrom && (!price || Object.keys(price).length === 0));
  
        const defaultPrice = {
          price: product.price,
          priceWithDiscount: product.priceWithDiscount,
          discountPercentage: product.discountPercentage,
          discount: product.discount,
        };
        
        this._activePrice =
          product.baseOptions?.length > 0 || product.variantOptions?.length > 0
            ? (price?.prices || defaultPrice)
            : defaultPrice;
    }
  }

  get displayFrom(): boolean {
    return this._displayFrom || this.combinedProductState?.product.displayPriceFrom;
  }
  
  get activePrice() {
    return this._activePrice;
  }
  
  pageId$ = this.cmsService.getCurrentPage().pipe(map(page => page.pageId));
  
  constructor(private cmsService: CmsService,
    private skuPageService: SkuPageService) {
  }
}
