import { Component, ChangeDetectionStrategy, OnDestroy, ViewContainerRef, ComponentRef, OnInit } from "@angular/core";
import { isNotNullable, ImageType, ImageGroup } from "@spartacus/core";
import { LaunchDialogService, LAUNCH_CALLER } from "@spartacus/storefront";
import { Subscription, BehaviorSubject, combineLatest, Observable } from "rxjs";
import { tap, filter, map } from "rxjs/operators";
import { ProductDetailsService } from "../../core/facade/product-details.service";
import { ProductMediasDialogComponent } from "../product-medias-dialog/product-medias-dialog.component";

@Component({
  selector: 'cx-product-images',
  styleUrls: [ './product-medias.component.scss' ],
  templateUrl: './product-medias.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductMediasComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription();
  active = 0;
  activeContainer = new BehaviorSubject(null);   
  gallery$: Observable<any>;

  constructor(private productDetailsService: ProductDetailsService,
    private dialogService: LaunchDialogService,
    private vcr: ViewContainerRef,) {
    }
    
  ngOnInit() {
    this.gallery$ = this.productDetailsService.combinedProductState$.pipe(

      tap(() => this.activeContainer.next(null)),
      filter(isNotNullable),
      tap((state) => {
        const image = state.images?.[ImageType.PRIMARY] ?? {};
        this.active = 0;
        state.images?.PRIMARY?.['product'] && this.activeContainer.next(image);
      })
    );
      
  }

  openImage(container: ImageGroup, i: number) {
    this.activeContainer.next(container);
    const image = container?.[ 'pdpImage' ]
      ?? container?.[ 'thumbnail' ]
      ?? container?.[ 'zoom' ];
    this.activeContainer.next(null);
    setTimeout(() => {
      this.activeContainer.next(container);
      this.active = i;
    }, 500);
  }

  openGallery(tabIndex = 0): void {
    const component = this.dialogService.launch(
      LAUNCH_CALLER.PRODUCT_IMAGE_ZOOM,
      this.vcr
    );
    if (component) {
      this.subscriptions.add(
        combineLatest([
          component,
          this.dialogService.dialogClose
        ]).pipe(
          tap(([ comp ]) => {
            const component =  comp as ComponentRef<ProductMediasDialogComponent> ;
              component.instance.active = this.active;
              component.instance.activeTab = tabIndex;
          }),
          filter(([ , close ]) => Boolean(close)),
          tap(([ comp ]) => {
            this.dialogService.clear(LAUNCH_CALLER.PRODUCT_IMAGE_ZOOM);
            comp?.destroy();
          })
        ).subscribe()
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
