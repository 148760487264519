import { Converter, ConverterService, Product, PRODUCT_NORMALIZER } from "@spartacus/core";
import {
  Addons,
  Embroidery,
  EmbroideryFont,
  Logo,
  LogoCategory,
  ProductConfigStepType,
  ProductConfiguration,
  ProductConfigurationStep,
  MatStyle,
  ProductLine,
  stepTypeSequence,
  MatStyleResponse,
  AutomotiveLogo, LogoItem,
  MatBinding, PersonalizedEmbroidery
} from "../../core/models/product-configuration.models";
import { embroideryFontSampleMap } from "../../core/facade/product-configuration.tools";
import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class ProductConfigurationNormalizer implements Converter<any, ProductConfiguration> {

  private readonly normalizerMap: { [ key: string ]: (source: any) => ProductConfigurationStep | ProductConfigurationStep[] } = {
    [ ProductConfigStepType.EMBROIDERY ]: (source: any) => this.normalizerEmbroidery(source),
    [ ProductConfigStepType.PERSONALIZED_EMBROIDERY]: (source: any) => this.normalizePersonalizedEmbroidery(source),
    [ ProductConfigStepType.MAT_STYLES ]: (source: any) => this.normalizeMatStyles(source),
    [ ProductConfigStepType.MAT_BINDINGS ]: (source: any) => this.normalizeMatBindings(source),
    [ ProductConfigStepType.PRODUCT_LINES ]: (source: any) => this.normalizeProductLines(source),
    [ ProductConfigStepType.LOGOS ]: (source: any) => this.normalizeLogos(source),
    [ ProductConfigStepType.AUTOMOTIVE_LOGO]: (source:any) => this.normalizeAutomotiveLogo(source),
    [ ProductConfigStepType.ADDONS ]: (source: any) => this.normalizeAddons(source)
  };

  constructor(private converter: ConverterService) {
  }


  convert(source: any, target?: ProductConfiguration): ProductConfiguration {
    target = target ?? {} as ProductConfiguration;

    target.metaData = source.metaData;

    for (const stepType of stepTypeSequence) {
      const normalizer = this.normalizerMap[ stepType ];
      if (normalizer) {
        const item = normalizer(source);
        if (Array.isArray(item)) {
          target.steps = [
            ...( target?.steps ?? [] ),
            ...item
          ];
        } else if (!!item) {
          target.steps = target.steps || [];
          target.steps.push(item);
        }
      }
    }
    return target;
  }


  private normalizerEmbroidery(source: any): Embroidery {
    const embroidery = source.embroidery as Embroidery;
    if (!embroidery || Object.keys(embroidery)?.length === 0) {
      return null;
    }
    embroidery.fonts.forEach((f: EmbroideryFont) => {
      f.fontMedias = embroideryFontSampleMap[ f.code ]
        ?? embroideryFontSampleMap[ 'SC' ];
    });
    embroidery.stepType = ProductConfigStepType.EMBROIDERY;
    embroidery.label = 'Text & Lettering Style';
    embroidery.note = embroidery.note ?? 'Note: A sample representation is shown above. Thread colors are pre-set based on the color of the fabric; no substitution is possible. Actual size of letters are 1 1/8 in. tall. For embroidery location details, please see below.';
    return embroidery;
  }

  private normalizePersonalizedEmbroidery(source: any): PersonalizedEmbroidery {
    const personalizedEmbroidery = source.personalizedEmbroidery as PersonalizedEmbroidery;
    return !personalizedEmbroidery ? null : {
      stepType: ProductConfigStepType.PERSONALIZED_EMBROIDERY,
      label: "Personalized Embroidery",
      code: personalizedEmbroidery.code,
      fonts: personalizedEmbroidery.fonts,
      letteringColors: personalizedEmbroidery.letteringColors,
      price: personalizedEmbroidery.price,
      priceWithDiscounts: personalizedEmbroidery.priceWithDiscounts
    };
  }

  private normalizeMatStyles(source: any): MatStyle {
    const list = source.matStyleOptions as MatStyleResponse[];

    return !list || list.length === 0
      ? null // Return null if no mat styles are present
      : {
          stepType: ProductConfigStepType.MAT_STYLES,
          label: "Mat Style",
          options: list.map(matStyle => ({
            basePartNumber: matStyle.basePartNumber,
            bullets: matStyle.bullets,
            illustrationCode: matStyle.illustrationCode,
            image: matStyle.image,
            price: matStyle.price,
            priceWithDiscount: matStyle.priceWithDiscount,
            sku: matStyle.sku,
            userInterfaceDescription: matStyle.userInterfaceDescription,
            discount: matStyle.discount,
            discountPercentage: matStyle.discountPercentage,
          }))
        };
  }

  private normalizeMatBindings(source: any): MatBinding {
    const matBinding = source.premiumBinding as MatBinding;

    return !matBinding
      ? null // Return null if no binding is present
      : {
          ...matBinding,
          stepType: ProductConfigStepType.MAT_BINDINGS,
        };
  }

  private normalizeProductLines(source: any): ProductLine[] {
    const list = source.productLineOptions as ProductLine[];
    return !list || list?.length === 0 || !source.metaData.hasProductLines
      ? []
      : list.map(line => {
        return {
          ...line,
          stepType: ProductConfigStepType.PRODUCT_LINES,
          label: line.userInterfaceDescription
        };
      });
  }

  private normalizeLogos(source: any): Logo {
    const categories = source.logos as LogoCategory[];
    if (!categories || !categories?.length) {
      return null;
    }
    const isImplicitLogo = source.hasMandatoryLogo
      && categories?.length === 1
      && categories[ 0 ]?.logos?.length === 1;
    return {
      categories,
      label: 'Logos',
      stepType: ProductConfigStepType.LOGOS,
      hideStep: isImplicitLogo,
      defaultLogo: isImplicitLogo
        ? categories[ 0 ].logos[ 0 ]
        : undefined
    };
  }

  private normalizeAutomotiveLogo(source: any): AutomotiveLogo {
    const list = source.automotiveLogo?.logos as LogoItem[];

    return !list || list.length === 0
      ? null // Return null if no automotiveLogo are present
      : {
        stepType: ProductConfigStepType.AUTOMOTIVE_LOGO,
        label: "Automotive Logo",
        logos: list.map(logo => ({
          image: logo.image,
          logoCode: logo.logoCode,
          name: logo.name,
          price: logo.price,
          priceWithDiscounts: logo.priceWithDiscounts
        }))
      };
  }

  private normalizeAddons(source: any): Addons {
    if (!source.addons) {
      return null;
    }
    const addons = source.addons.map((product: Product) =>
      this.converter.convert(product, PRODUCT_NORMALIZER)
    );
    return {
      label: 'Addons',
      stepType: ProductConfigStepType.ADDONS,
      products: addons
    };
  }
}
