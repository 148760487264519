<div class="step-select-vehicle">

  <div class="row">

    <!-- YMM FORM AREA -->
    <aside class="col-12 col-md-4 col-lg-3">
      <cx-ymm-form style="margin-bottom: 20px !important;"
        [displayShopButton]="false"
        [fillActive]="true"
        [showHeading]="false"
        [hideHelpText]="true"
        >
      </cx-ymm-form>
      <div class="submodel-form__selector">
        <select (change)="onSubModelChange($event)" [formControl]="subModelControl" class="form-control" 
          id="subModelSelector" >
          <option [ngValue]="null" hidden>
            Submodel
          </option>
          <option *ngFor="let sub of (subModels$ | async)?.submodels" [value]="sub.value">
            {{ sub.description }}
          </option>
        </select>
      </div>
    </aside>

    <!-- FITMENT AREA -->
    <section class="col-12 col-md-8 col-lg-9">

      <ng-container *ngIf="displayFitmentArea$ | async">

        <ng-container *ngIf="fit$ | async; else noFit">
          <div class="step-select-vehicle__submodel">

            <!-- FORM -->
            <form [formGroup]="form" class="submodel-form">

              <ng-container *ngIf="subModelOptions$ | async as options">

                <!-- SUBMODEL OPTIONS (aka PART NUMBER) -->
                <div class="submodel-form__options">

                  <h6 class="mb-3 mt-4 text-weight-bold">
                    Vehicle’s Fitment Details
                  </h6>
                  <div *ngFor="let option of options.options"
                       class="d-flex my-3">
                    <input
                      (change)="onSubModelOptionChange(option)"
                      [id]="option.value"
                      [value]="option.value"
                      class="form-check-input form-check-input__success"
                      formControlName="subModelOption"
                      type="radio"/>
                    <label
                      [for]="option.value"
                      class="label-content mb-0 text-weight-medium">
                      {{ option.description }}
                    </label>
                  </div>
                </div>
              </ng-container>

            </form>
          </div>
        </ng-container>

        <!-- NO FIT INFORMATION -->
        <ng-template #noFit>
          <ng-container *ngIf="vehicle$ | async as vehicle">
            <div class="step-select-vehicle__no-fit">
              <div class="message-box message-box__sorry message-box__lg">
                <div class="message-box__heading">We're sorry</div>
                <div>
                  This product is not available for the {{ vehicle.name }}.  Try another product or submit a <a target="_blank" class="message-box__link" href="https://support.covercraft.com/hc/en-us/requests/new?tf_22073736828827=car_and_truck&tf_18924042513435=new_pattern_request">new pattern request</a>.
                </div>
              </div>
            </div>
          </ng-container>
        </ng-template>

      </ng-container>
    </section>
  </div>
</div>
