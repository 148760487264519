<ng-container *ngIf="data$ | async as header">

  <section [ngClass]="{'vehicle-step': header.isVehicleStep}"
           class="product-lines-header">


    <!-- Vehicle Selection Header -->
    <ng-container *ngIf="header.isVehicleStep; else vehicle">
      <div>
        <h5 class="product-lines-header__vehicle-title">
          Confirm your <span style="color: #C7000B;"> vehicle </span>
        </h5>
        <div class="product-lines-header__vehicle-info">
          In order to be sure you are purchasing the correct product,
          we need a bit more information.
        </div>
        <!-- Close Button -->
        <button (click)="close()" class="cover-dialog__close-btn">
          <svg-icon iconId="iconClose"></svg-icon>
        </button>
      </div>
    </ng-container>


    <ng-template #vehicle>

      <!-- Title -->
      <h5 class="product-lines-header__title">
        Configure your Product
      </h5>

      <div class="d-flex align-items-center py-1 py-md-0">

        <!-- Swatch -->
        <div class="product-lines-header__swatch">
          <cx-media [container]="{url: header.swatch?.option?.image}"></cx-media>
        </div>

        <!-- Vehicle -->
        <div class="product-lines-header__vehicle">
          <div class="product-lines-header__vehicle--picture">
            <ng-container *ngIf="header?.vehicle?.image.startsWith('/assets/static/vehicles'); else remoteImage">
              <img [src]="header.vehicle.image">
            </ng-container>
            <ng-template #remoteImage>
              <cx-media [container]="{url: header.vehicle.image}"></cx-media>
            </ng-template>
          </div>
          <div class="product-lines-header__vehicle--name">
            {{ header.vehicle.name }}
          </div>
        </div>

      </div>

      <!-- Action -->
      <div class="product-lines-header__action">
        <button
          (click)="changeVehicle()"
          class="btn btn-primary"
          type="button">
          Change
        </button>
      </div>

      <!-- Price -->
      <div class="product-lines-header__cost">
        <div class="product-lines-header__cost--label">Total Cost</div>
        <div class="product-lines-header__cost--value">
          {{ header.price?.priceWithDiscount | currency }}
        </div>
      </div>

      <!-- Close -->
      <button (click)="close()" class="cover-dialog__close-btn">
        <svg-icon iconId="iconClose"></svg-icon>
      </button>

    </ng-template>

  </section>
</ng-container>

