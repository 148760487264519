import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { CmsService, Image, ImageGroup, Product, SemanticPathService, VariantOption, WindowRef } from '@spartacus/core';
import { ImageLoadingStrategy, ProductGridItemComponent, ProductListItemContextSource } from '@spartacus/storefront';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, take } from 'rxjs/operators';
import { ProductDetailsPrice, QualifierOptionValue } from '../core/models/product-details.models';
import { GarageService } from '../../ymm/core/facade/garage.service';
import { ProductDetailsOccAdapter } from "../core/occ/product-details-occ.adapter";
import { Router } from "@angular/router";
import { DataLayerService } from 'src/app/spartacus/features/data-layer/data-layer.service';
import { ProductDetailsService } from '../core/facade/product-details.service';

@Component({
  selector: 'custom-product-card',
  templateUrl: './custom-product-card.component.html',
  styleUrls: [ './custom-product-card.component.scss' ],
})

export class CustomProductCardComponent extends ProductGridItemComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  @Input() override product: Product | undefined;
  @Input() context: 'SEARCH' | 'CAROUSEL' = 'SEARCH';
  primaryImage$ = new BehaviorSubject<Image | undefined>(undefined);
  hoverImage$ = new BehaviorSubject<Image>(undefined);
  price$ = new BehaviorSubject<ProductDetailsPrice | undefined>(undefined);
  combinedProductState$: Observable<any>;
  isMobile = false;
  isHoverableDevice = false;

  get url(): string {
    const segments = this.product && this.semanticPathService.transform({
      cxRoute: 'product',
      params: this.product
    });
    return segments?.length
      ? this.router.createUrlTree(segments).toString()
      : '';
  }

  craftName$ = this.garageService.getActiveVehicle()
    .pipe(
      switchMap((vehicle) => {
        return this.product.universalFit
          ? of('Universal Fit')
          : !vehicle
            ? of(undefined)
            : this.garageService.getFit(this.product.code, vehicle.ymm)
              .pipe(
                map(fit => fit ? vehicle.name : undefined)
              )

      })
    );

  isMMPage$ = this.cmsService.getCurrentPage().pipe(
    map(page => page.template === 'YMMCategoryPageTemplate' && page.label === 'shop' && !page.year)
  );

  
  constructor(productListItemContextSource: ProductListItemContextSource,
              private garageService: GarageService,
              private productDetailsAdapter: ProductDetailsOccAdapter,
              private winRef: WindowRef,
              private semanticPathService: SemanticPathService,
              private cmsService: CmsService,
              private router: Router,
              private dataLayerService: DataLayerService) {
    super(productListItemContextSource);
  }

  ngOnInit(): void {        
    this.combinedProductState$ = combineLatest([
      of(this.product),
      this.price$
    ]).pipe(
      debounceTime(400),
      map(([product, price]) => {     
        return { product, price };
      }),
      distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
    );

    this.loadProductImages();
    this.definePrice(this.getSelectedVariant());

    if (this.winRef.isBrowser()) {
      const isHoverableDevice = window.matchMedia(
        '(hover: hover) and (pointer: fine)'
      );
      if (isHoverableDevice.matches) {
        this.isHoverableDevice = true;
      }
    }
  }

  @HostListener("click") onClick(){
    this.dataLayerService.selectItemEvent(this.product);
  }

  loadProductImages(): void | boolean {
    const selected = this.product?.baseOptions?.[ 0 ]?.selected;
    const firstOption = this.product?.variantOptions?.[ 0 ];
    const variant = !selected
      ? firstOption
      : this.product.variantOptions?.find(v => v?.code === selected?.code
      || v.variantOptionQualifiers?.[ 0 ]?.value === selected?.variantOptionQualifiers?.[ 0 ]?.value
    ) ?? firstOption;
    const primaryImage = this.product.images?.[ 'PRIMARY' ] as ImageGroup;
    const productFormat = primaryImage?.[ 'product' ] as Image;
    this.hoverImage$.next({ url: this.product?.secondImage?.url || '' });
    const image = { url: variant?.variantProductImage ?? productFormat?.url ?? '' }
    this.primaryImage$.next(image);
    if (image?.url) {
      return false;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getSelectedVariant(): VariantOption {
    const selected = this.product?.baseOptions?.[ 0 ]?.selected;
    const firstOption = this.product?.variantOptions?.[ 0 ];

    return !selected
      ? firstOption
      : this.product.variantOptions?.find(v => v?.code === selected?.code
      || v.variantOptionQualifiers?.[ 0 ]?.value === selected?.variantOptionQualifiers?.[ 0 ]?.value
    ) ?? selected ?? firstOption;
  }

  handlerVariant(variant: VariantOption) {
    this.primaryImage$.next({ url: variant.variantProductImage || '' });
    this.definePrice(variant);
  }

  private definePrice(variantOption: VariantOption): void {
    const variantOptionQualifier = variantOption?.variantOptionQualifiers?.[ 0 ];
    if (variantOptionQualifier) {
      if (this.context === 'SEARCH') {
        this.price$.next(this.extractVariantPrice(variantOption));
        return;
      }
      const option: QualifierOptionValue = {
        qualifier: variantOptionQualifier.qualifier,
        option: variantOptionQualifier
      }
      this.subscription.add(
        this.productDetailsAdapter.loadPrice(this.product.code, [ option ])
          .pipe(take(1))
          .subscribe(price => {
            const finalPrice = price && Object.keys(price)?.length > 0
              ? price
              : this.getProductPrice();
            this.price$.next(finalPrice);
          })
      );
    } else {
      this.price$.next(!!variantOption
        ? this.extractVariantPrice(variantOption)
        : this.getProductPrice());
    }
  }

  private extractVariantPrice(variant: VariantOption) {
    return {
      prices: {
        discount: variant.discount,
        price: variant.priceData,
        priceWithDiscount: variant.priceWithDiscount,
        discountPercentage: variant.discountPercentage
      }
    }
  }


  private getProductPrice(): ProductDetailsPrice {
    return {
      prices: {
        price: this.product.price,
        priceWithDiscount: this.product.priceWithDiscount,
        discountPercentage: this.product.discountPercentage,
        discount: this.product.discount
      }
    }
  }

  protected readonly ImageLoadingStrategy = ImageLoadingStrategy;
}
