import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { LoadVariantsResponse, ProductDetailsPrice, QualifierOptionValue } from "../models/product-details.models";
import { ConverterService, DynamicAttributes, Image, ImageGroup, OccEndpointsService, Product, PRODUCT_NORMALIZER, ProductScope } from "@spartacus/core";
import { Observable } from "rxjs";
import { map, pluck } from "rxjs/operators";
import { SkuPageService } from "../../../sku-landing-page/skupage.service";

@Injectable({ providedIn: 'root' })
export class ProductDetailsOccAdapter {
  constructor(
    private http: HttpClient,
    private endpointService: OccEndpointsService,
    private converterService: ConverterService,
    private skuService: SkuPageService
  ) {
  }

  loadProduct(productCode: string, scope: string | ProductScope = ProductScope.DETAILS): Observable<Product> {
    const attributes: DynamicAttributes = {
      urlParams: { productCode },
      scope,
    };
    if (this.skuService.isSKUPage()) {
      attributes.queryParams = {
        sku: this.skuService.getCurrentPartNumber()
      }
    }
    const url = this.endpointService.buildUrl(`product_${ scope }`, attributes);
    return this.http.get<Product>(url).pipe(this.converterService.pipeable(PRODUCT_NORMALIZER));
  }

  loadVariantOptions(product: string, variantOptions: QualifierOptionValue[])
    : Observable<LoadVariantsResponse> {
    const variantsUrl = this.endpointService.buildUrl(
      'loadVariants',
      { queryParams: this.getQueryParams(product, variantOptions) }
    );
    return this.http.post<LoadVariantsResponse>(variantsUrl, {});
  }


  loadPrice(product: string, variantOptions: QualifierOptionValue[], partNumber?: string)
    : Observable<ProductDetailsPrice> {
    const priceUrl = this.endpointService.buildUrl(
      'updatePrice',
      { queryParams: this.getQueryParams(product, variantOptions, partNumber) }
    );
    return this.http.post<ProductDetailsPrice>(priceUrl, {});
  }

  loadGallery(product: string, variantOptions: QualifierOptionValue[])
    : Observable<ImageGroup> {
    const imagesUrl = this.endpointService.buildUrl(
      'updateImages',
      { queryParams: this.getQueryParams(product, variantOptions) }
    );
    return this.http.post<{ variantImages: Image[] }>(imagesUrl, {})
      .pipe(
        pluck('variantImages'),
        map(images => {
          if (!images?.length) {
            return null;
          }
          return {
            product: this.mapImage(images, 'product'),
            thumbnail: this.mapImage(images, 'thumbnail'),
            //zoom: this.mapImage(images, 'zoom'),
            cartIcon: this.mapImage(images, 'cartIcon'),
            PDPImage: this.mapImage(images, 'PDPImage'),
            superZoom: this.mapImage(images, 'superZoom')
          }
        })
      );
  }


  private getQueryParams(product: string, variantOptions: QualifierOptionValue[], partNumber?: string): any {
    let queryParams: any = {
      product
    }
    if (partNumber) {
      queryParams.partNumber = partNumber;
    }
    if (variantOptions?.length) {
      queryParams = {
        ...queryParams,
        ...variantOptions
          .map(opt => ( { [ opt.qualifier ]: opt.option?.value } ))
          .reduce((prev, curr) => ( { ...prev, ...curr } ))
      }
    }
    return queryParams;
  }


  private mapImage(variantImages: Image[], format: string): Image {
    const image = variantImages?.find((v) => v.format === format);
    return {
      ...image,
      altText: image?.altText ?? '',
      galleryIndex: image?.galleryIndex ?? 0,
    };
  }
}
