<ng-container *ngIf="{
  product: product$| async,
  thumbs: thumbs$ | async
} as data">
  <section class="product-gallery-thumbnails">

    <!-- BULLETS (Only Mobile) -->
    <div class="product-gallery-thumbnails__bullets">
      <ng-container *ngFor="let thumb of data.thumbs; index as i">
        <button
          (click)="openImage(thumb, i, 'image')"
          [class.active]="active === i"
          class="product-gallery-thumbnails__bullets--btn">
        </button>
      </ng-container>
    </div>

    <!-- THUMBNAILS -->
    <aside class="product-gallery-thumbnails__thumbs">
      <ng-container *ngFor="let thumb of data.thumbs | slice: 0: (maxGalleryItems > 0 ? maxGalleryItems - 1 : data.thumbs.length); index as i; trackBy:identify">
        <button class="product-gallery-thumbnails__thumbs--item" [ngClass]="{'active': active === i}">
          <cx-media
            (focus)="openImage(thumb, i, 'image')"
            [container]="thumb"
            format="pdpImage"
            tabindex="0">
          </cx-media>
        </button>
      </ng-container>

      <!-- More buttons:
           If there are more than 4 images, then show it -->
      <ng-container *ngIf="data?.thumbs?.length > 4">
        <button class="product-gallery-thumbnails__more" (click)="onOpenGallery()">
          <cx-media [container]="data.thumbs[4]" format="thumbnail" tabindex="0"></cx-media>

          <div class="product-gallery-thumbnails__overlay">
            <div class="product-gallery-thumbnails__overlay--highlight">
              +{{ data.thumbs.length - 4 }}
            </div>
            <div>{{ 'productCarousel.images' | cxTranslate }}</div>
          </div>
        </button>
      </ng-container>

      <!-- Vídeos Button -->
      <button *ngIf="data?.product?.galleryVideos?.length" class="product-gallery-thumbnails__videos" (click)="onOpenGallery(1)">
        <div class="product-gallery-thumbnails__overlay">
          <i class="bi bi-play-btn"></i>
          <div>{{ 'productCarousel.videos' | cxTranslate }}</div>
        </div>
      </button>
    </aside>
  </section>
</ng-container>
