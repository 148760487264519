import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { ProductDetailsActions, ProductDetailsSelectors } from "../store";
import { debounceTime, distinctUntilChanged, distinctUntilKeyChanged, filter, map, shareReplay, startWith, switchMap, take } from "rxjs/operators";
import { CurrentProductService } from "@spartacus/storefront";
import { Image, ImageGroup, isNotNullable, Product, VariantOptionQualifier } from "@spartacus/core";
import { ProductDetailsState } from "../store/product-details.state";
import { combineLatest, Observable } from "rxjs";
import { deepCloning } from "../../../../../tools/tools";
import { VariantViewBag } from "../models/product-details.models";
import { buildKeyChainForVariantOptions, buildKeyChainForPrice, isColor } from "../../configurator/core/facade/product-configuration.tools";
import { Actions, ofType } from "@ngrx/effects";

@Injectable({ providedIn: 'root' })
export class ProductDetailsService {

  product$ = this.currentProductService.getProduct()
    .pipe(
      filter(isNotNullable),
      distinctUntilKeyChanged('code'),
      shareReplay(1)
    );

  galleryIsProcessing$ = this.actions$.pipe(
    ofType(ProductDetailsActions.loadVariantsProcessing),
    map(({ isProcessing }) => isProcessing)
  );

  variants$: Observable<VariantViewBag[]> = combineLatest([
    this.product$.pipe(
      switchMap(product => {
          return this.store.select(
            ProductDetailsSelectors.selectVariants(product.code)
          )
        }
      )
    ),
    this.store.select(ProductDetailsSelectors.selectActiveVariantOptions),
  ]).pipe(
    map(([ variants, activeOptions ]) => {
      if (!variants) {
        return [];
      }
      return Object.keys(variants)
        .map(k => {
          const option = activeOptions?.find(opt => opt.qualifier === k)
          const v = variants[ k ];
          const selected = option
            ? v.options[ v.activeKeyChain ]?.find(opt => opt.value === option?.option?.value)
            : null
          return {
            qualifier: k,
            options: v.options[ v.activeKeyChain ],
            selected
          }
        });
    }),
    shareReplay(1)
  );


  activeGallery$ = combineLatest([
    this.product$,
    this.getSelectedVariants().pipe(
      map(v => {
        return v?.filter(v => isColor(v.qualifier))
      }),
      distinctUntilChanged((v1, v2) => {
        if (!v1?.length || v2.length) {
          return false;
        }
        return v1?.map(
          (v, i) =>
            v.qualifier === v2[ i ].qualifier
            && v.option.value === v2[ i ].option.value
        )?.reduce((b1, b2) => b1 && b2)
      }),
    ),
    this.store.select(ProductDetailsSelectors.selectGallery),
    this.galleryIsProcessing$.pipe(startWith(false))
  ]).pipe(
    debounceTime(400),
    map(([ product, activeOptions, gallery ]) => {
      if (!activeOptions?.length) {
        if (isColor(product.baseOptions?.[ 0 ]?.options?.[ 0 ]?.variantOptionQualifiers?.[ 0 ]?.qualifier)) {
          return null;
        } else {
          return product.images;
        }
      }
      const keyChain = buildKeyChainForVariantOptions(product.code, activeOptions, true);
      const images = deepCloning(product.images);
      if (images && gallery[ keyChain ]) {
        ( images[ 'GALLERY' ] as Image[] ).unshift(gallery[ keyChain ]);
        ( images[ 'PRIMARY' ] as ImageGroup ) = gallery[ keyChain ];
      }
      return images;
    }),
    distinctUntilChanged(),
    shareReplay(1)
  );


  combinedProductState$: Observable<{
    product: Product;
    activeOptions: any;
    price: any;
    images: any;
  }> = combineLatest([
    this.product$,
    this.getSelectedVariants().pipe(filter(variants => variants !== undefined)),
    this.store.select(ProductDetailsSelectors.selectPrices),
    this.store.select(ProductDetailsSelectors.selectGallery)
  ]).pipe(
    debounceTime(400),
    map(([product, activeOptions, prices, gallery]) => {
      let images = null;
      let price = null;
  
      if (!activeOptions?.length) {   
        if (product && !Object.keys(prices).length && !Object.keys(gallery).length) {
          if (!isColor(product.baseOptions?.[0]?.options?.[0]?.variantOptionQualifiers?.[0]?.qualifier)) {
            images = product.images;
          }
          return { product, activeOptions, price, images };
        }
        return null;
      } else {
        const keyChain = buildKeyChainForVariantOptions(product.code, activeOptions, true);
        images = deepCloning(product.images);
        if (images && gallery[ keyChain ]) {
          ( images[ 'GALLERY' ] as Image[] ).unshift(gallery[ keyChain ]);
          ( images[ 'PRIMARY' ] as ImageGroup ) = gallery[ keyChain ];
        }
        const priceKeyChain = buildKeyChainForPrice(product.code, activeOptions);
        price = prices[priceKeyChain];   

        return { product, activeOptions, price, images };
      }
    }),
    distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
    shareReplay(1)
  );
  

  constructor(private store: Store<ProductDetailsState>,
              private currentProductService: CurrentProductService,
              private actions$: Actions) {

  }

  getAllVariants() {
    return this.store.select(
      ProductDetailsSelectors.allVariants
    );
  }

  getSku(): Observable<string> {
    return this.store.select(ProductDetailsSelectors.selectSku);
  }

  getSelectedVariants() {
    return this.store.select(
      ProductDetailsSelectors.selectActiveVariantOptions
    )
  }

  activateOption(productCode: string, qualifier: string, option: VariantOptionQualifier) {
    this.store.dispatch(
      ProductDetailsActions.selectOption({
        qualifier,
        option
      }));
    combineLatest([
      this.store.select(ProductDetailsSelectors.selectVariants(productCode)),
      this.getSelectedVariants().pipe(debounceTime(50), filter(isNotNullable)),
    ]).pipe(take(1))
      .subscribe(([ variants, activeOptions ]) => {
        const variantArray = Object.keys(variants).map(k => variants[ k ]);
        const idx = variantArray.findIndex(v => v.qualifier === qualifier);
        const next = variantArray[ idx + 1 ];
        const hasColor = isColor(qualifier) || activeOptions.some(opt => isColor(opt.qualifier));
        if (hasColor) {
          this.store.dispatch(
            ProductDetailsActions.loadGallery({
              product: productCode, options: activeOptions
            })
          );
        }
        if (next) {
          this.loadVariant(productCode, next.qualifier)
        }
      });
  }


  activateOptionForCurrentProduct(qualifier: string, option: VariantOptionQualifier): void {
    this.product$.pipe(take(1))
      .subscribe(product => {
          this.activateOption(product.code, qualifier, option);
        }
      );
  }

  loadVariantOptions(product: Product, variantOption: VariantOptionQualifier): void {
    this.store.dispatch(
      ProductDetailsActions.loadVariantOptions({
        product: product.code,
        qualifier: variantOption.qualifier,
        selectedOptions: []
      })
    );
  }

  loadVariant(product: string,
              qualifier: string): void {
    this.store.select(ProductDetailsSelectors.selectActiveVariantOptions)
      .pipe(take(1))
      .subscribe(selectedOptions => {
        this.store.dispatch(
          ProductDetailsActions.loadVariantOptions({
            product,
            qualifier,
            selectedOptions
          })
        );
      });
  }

  startVariantForProduct(product: Product, options: VariantOptionQualifier[]): void {
    this.store.dispatch(
      ProductDetailsActions.startVariants({
        productCode: product.code,
        options
      })
    );
  }

  resetSelectedVariants(): void {
    this.store.dispatch(ProductDetailsActions.clearSelectedVariantOptions())
  }
}

