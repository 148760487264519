import { Component } from '@angular/core';
import { Address, GlobalMessageService, TranslationService } from '@spartacus/core';
import { Card } from '@spartacus/storefront';
import { AddressBookComponent, AddressBookComponentService } from "@spartacus/user/profile/components";
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddressCardService } from './address-card.service';


@Component({
  selector: 'cx-address-book',
  templateUrl: './address-book.component.html',
})
export class CustomAddressBookComponent extends AddressBookComponent {


  constructor(
    service: AddressBookComponentService,
    translation: TranslationService,
    globalMessageService: GlobalMessageService,
    private addressCardService: AddressCardService
  ) {
    super(service, translation, globalMessageService);
  }

  override getCardContent(address: Address): Observable<Card> {
    return combineLatest([
      this.translation.translate('addressCard.default'),
      this.translation.translate('addressCard.setAsDefault'),
      this.translation.translate('common.delete'),
      this.translation.translate('common.edit'),
      this.translation.translate('addressBook.areYouSureToDeleteAddress'),
      this.translation.translate('addressCard.phoneNumber'),
      this.translation.translate('addressCard.mobileNumber'),
    ]).pipe(
      map(
        ([
          defaultText,
          setAsDefaultText,
          textDelete,
          textEdit,
          textVerifyDeleteMsg,
          textPhone,
          textMobile,
        ]) => {
          const actions: { name: string; event: string }[] = [];

          if (!address.defaultAddress) {
            actions.push({ name: setAsDefaultText, event: 'default' });
          }

          actions.push({ name: textEdit, event: 'edit' });
          actions.push({ name: textDelete, event: 'delete' });

          return {
            role: 'region',
            text: this.addressCardService.getAddressCard(address),
            actions: actions,
            header: address.defaultAddress ? `✓ ${defaultText}` : '',
            deleteMsg: textVerifyDeleteMsg,
            label: address.defaultAddress
              ? 'addressBook.defaultDeliveryAddress'
              : 'addressBook.additionalDeliveryAddress',
          } as Card;
        }
      )
    );
  }

}
