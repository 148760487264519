import { Injectable, RendererFactory2 } from "@angular/core";
import { Router } from "@angular/router";
import { WindowRef } from "@spartacus/core";
import { PageMetaLinkService } from "@spartacus/storefront";
import { SanitizeProductUrlPipe } from "src/app/components/pipes/sanitize-product-url/sanitize-product-url.pipe";

@Injectable({ providedIn: 'root' })
export class CustomPageMetaLinkService extends PageMetaLinkService {
  constructor(winRef: WindowRef, rendererFactory: RendererFactory2, private router: Router) {
    super(winRef, rendererFactory);
  }

  public override setCanonicalLink(url: string | undefined): void {
    let link: HTMLLinkElement = this.winRef.document.querySelector(
      'link[rel="canonical"]'
    ) as HTMLLinkElement;

    if (!url && !link?.href) {
      // Removing the link is an edge case, but useful if the canonical url
      // is created in CSR while developing/testing.
      link?.remove();
      return;
    }
    
    const urlObj = new URL(url ?? link.href);
    const origin = urlObj.origin;
    let path = urlObj.pathname;
   
    const productPattern = /^\/product\//;
    if (productPattern.test(path))
    {
      var sanitizePipe = new SanitizeProductUrlPipe();
      path = sanitizePipe.transform(path);  
    }

    var href = origin + path;

    if (!link) {
      link = this.renderer.createElement('link');
      link.rel = 'canonical';
      link.href = href;
      this.renderer.appendChild(this.winRef.document.head, link);
    } else {
      link?.setAttribute('href', href);
    }
  }
}
