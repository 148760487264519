import { Injectable } from "@angular/core";
import { AddonVariant } from "./addons-model";
import { BehaviorSubject, combineLatest, Observable, of } from "rxjs";
import { map, pluck, take } from "rxjs/operators";
import { LoadVariantsResponse, ProductDetailsPrice } from "../../../../core/models/product-details.models";
import { Image, ImageGroup, OccEndpointsService, Product, VariantOptionQualifier } from "@spartacus/core";
import { HttpClient } from "@angular/common/http";
import { isColor } from "../../../core/facade/product-configuration.tools";


@Injectable()
export class ConfiguratorVariantService {
  private _variants: AddonVariant[] = [];

  private _variantsEvent = new BehaviorSubject<AddonVariant[]>(this._variants);
  variants$ = this._variantsEvent.asObservable();

  constructor(private http: HttpClient,
              private endpointService: OccEndpointsService) {
  }

  setAddonVariantState(addonVariants: AddonVariant[]): void {
    this._variants = addonVariants;
    this.emitVariants();
  }

  startVariants(product: Product): void {
    const options = product.baseOptions?.[ 0 ]?.options?.[ 0 ].variantOptionQualifiers.filter(v => !v.hiddenFromUi);
    if (options?.length) {
      this._variants = options.map(option => {
        return {
          qualifier: option.qualifier,
          options: []
        };
      });
      const firstDefaultValue = isColor(options[ 0 ]?.qualifier)
        ? product.baseOptions[ 0 ].selected?.variantOptionQualifiers?.find(v => v.qualifier === options[ 0 ].qualifier)
        : undefined;
      this.load(
        product.code,
        firstDefaultValue.qualifier,
        [],
        firstDefaultValue
      );
    }
  }


  setVariant(code: string, qualifier: string, selected: VariantOptionQualifier): void {
    const idx = this._variants.findIndex(v => v.qualifier === qualifier);
    const variant = this._variants[ idx ];
    variant.selected = selected;
    this._variants.slice(idx + 1)?.forEach(v => v.selected = undefined);
    const selection = this._variants.slice(0, idx + 1)?.map(v => v.selected).filter(Boolean) ?? [];
    const galleryLoader$ = isColor(variant.qualifier) && selected
      ? this.loadGallery(code, selection)
      : of(undefined);
    combineLatest([
      this.loadPrice(code, selection),
      galleryLoader$,
    ]).pipe(take(1))
      .subscribe(([ price, gallery ]) => {
        variant.gallery = gallery;
        variant.price = price;
        if (this._variants.length > idx + 1) {
          const next = this._variants[ idx + 1 ];
          this.load(code, next.qualifier, selection);
        } else {
          this.emitVariants();
        }
      });
  }

  private load(
    code: string,
    qualifier: string,
    variantOptions: VariantOptionQualifier[],
    selected?: VariantOptionQualifier
  ): void {
    const idx = this._variants.findIndex(v => v.qualifier === qualifier);
    const variant = this._variants[ idx ];
    this.loadVariantOptions(code, variantOptions)
      .pipe(take(1))
      .subscribe(response => {
        variant.options = response.variants;
        if (selected) {
          this.setVariant(code, qualifier, selected);
        } else {
          this.emitVariants();
        }
      })
  }


  private loadVariantOptions(product: string, variantOptions: VariantOptionQualifier[])
    : Observable<LoadVariantsResponse> {
    const variantsUrl = this.endpointService.buildUrl(
      'loadVariants',
      { queryParams: this.getQueryParams(product, variantOptions) }
    );
    return this.http.post<LoadVariantsResponse>(variantsUrl, {});
  }

  private loadPrice(product: string, variantOptions: VariantOptionQualifier[])
    : Observable<ProductDetailsPrice> {
    const priceUrl = this.endpointService.buildUrl(
      'updatePrice',
      { queryParams: this.getQueryParams(product, variantOptions) }
    );
    return this.http.post<ProductDetailsPrice>(priceUrl, {});
  }

  private emitVariants(): void {
    this._variantsEvent.next(this._variants);
  }

  private loadGallery(product: string, variantOptions: VariantOptionQualifier[])
    : Observable<ImageGroup> {
    const imagesUrl = this.endpointService.buildUrl(
      'updateImages',
      { queryParams: this.getQueryParams(product, variantOptions) }
    );
    return this.http.post<{ variantImages: Image[] }>(imagesUrl, {})
      .pipe(
        pluck('variantImages'),
        map(images => {
          if (!images?.length) {
            return null;
          }
          return {
            product: this.mapImage(images, 'product'),
            thumbnail: this.mapImage(images, 'thumbnail'),
            //zoom: this.mapImage(images, 'zoom'),
            cartIcon: this.mapImage(images, 'cartIcon'),
            PDPImage: this.mapImage(images, 'PDPImage'),
            superZoom: this.mapImage(images, 'superZoom')
          }
        })
      );
  }


  private getQueryParams(product: string, variantOptions: VariantOptionQualifier[]): any {
    let queryParams: any = {
      product
    }
    if (variantOptions.length) {
      queryParams = {
        ...queryParams,
        ...variantOptions
          .map(opt => ( { [ opt.qualifier ]: opt?.value } ))
          .reduce((prev, curr) => ( { ...prev, ...curr } ))
      }
    }
    return queryParams;
  }

  private mapImage(variantImages: Image[], format: string): Image {
    const image = variantImages?.find((v) => v.format === format);
    return {
      ...image,
      altText: image?.altText ?? '',
      galleryIndex: image?.galleryIndex ?? 0,
    };
  }


}
