/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import {
  ChangeDetectionStrategy,
  Component
} from '@angular/core';
import { SuggestedAddressDialogComponent } from "@spartacus/user/profile/components";

@Component({
  selector: 'cx-suggested-addresses-dialog',
  templateUrl: './suggested-addresses-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomSuggestedAddressDialogComponent extends SuggestedAddressDialogComponent {

}
