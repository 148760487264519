<!--
  Esse componente busca unificar as telas renderizadas em:
   - /search/xxxx
   - /c/custom_general
   - /shop/2024-nissan-kicks
 -->

<ng-template cxOutletRef="SearchResultsListPageTemplate" let-model>
  <ng-container *ngTemplateOutlet="productListTemplate"></ng-container>
</ng-template>

<ng-template cxOutletRef="YMMCategoryPageTemplate" let-model>
  <ng-container *ngTemplateOutlet="productListTemplate"></ng-container>
</ng-template>

<ng-template cxOutletRef="ProductListPageTemplate" let-model>
  <ng-container *ngTemplateOutlet="productListTemplate"></ng-container>
</ng-template>

<ng-template #productListTemplate>
  <ng-container *ngIf="{
    productsModel: products$ | async,
    hasProduct: hasProduct$ | async,
  } as data">

    <div class="plp-container w-100">
      <cx-page-slot position="plpTopBanner"></cx-page-slot>
      <div class="container">
        <cx-page-slot position="YearMakeModel"></cx-page-slot>
      </div>
      <ng-container *ngIf="isYmmPage$ | async as isYmmPage">
        <!-- Display category carousel on YMM and MM pages -->
        <ng-container *ngIf="topCategories.length > 0">
          <div class="container">
            <cx-custom-carousel [autoplayOn]="false" [items]="topCategories" [showArrowButtons]="true"
                              [template]="carouselItem" itemWidth="{{carouselItemWidth}}" [hideIndicators]="true"
                              role="region" class="top-categories">
            </cx-custom-carousel>
          </div>
          <ng-template #carouselItem let-item="item">
            <category-carousel-item [category]="item"></category-carousel-item>
          </ng-template>
        </ng-container>
      </ng-container>

      <div class="container">

        <ng-container *ngIf="data.hasProduct">
          <cx-page-slot position="ProductSearchDetails"></cx-page-slot>

          <div class="row">
            <div class="col-12 col-sm-3 col-ProductLeftRefinements">
              <cx-page-slot position="ProductLeftRefinements"></cx-page-slot>
            </div>

            <div class="col-12 col-sm-12 col-md-12 col-lg-9">
              <cx-page-slot position="ProductListSlot"></cx-page-slot>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-3"></div>
             <div [innerHTML]="categoryDescription | safeHtml" class="catDesc"></div>
          </div>
        </ng-container>
      </div>

      <div *ngIf="!data.hasProduct" class="w-100 m-t-20">
        <cx-page-slot position="NoResultsFound"></cx-page-slot>
      </div>

      <cx-page-slot position="plpBottomBanner"></cx-page-slot>
    </div>
  </ng-container>

  <!-- <cx-page-slot position="Section4"></cx-page-slot>
  <cx-page-slot position="NoResultsFoundSlot"></cx-page-slot>
  <cx-page-slot position="NoResultsFoundSlot2"></cx-page-slot> -->
</ng-template>
