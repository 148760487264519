

<nav [attr.aria-label]="navAriaLabel" class=" ">
  <ul style="height: 100%;">
    <div *ngIf="!iconDisabled" class="d-lg-none d-md-flex d-sm-flex d-xs-flex  w-100  align-items-center justify-space-around mobile-icons-container">
      <!-- THIS WILL BE AVAILABLE IN FUTURE RELEASES -->
<!--      <div style="display: inline-block;" class="iconDiv" tabindex="0">-->
<!--        <span class="bi bi-heart ml-1 d-lg-none"></span>-->
<!--      </div>-->

      <div *ngIf="user$ | async as user;"class="iconDiv" style="display: inline-block;" tabindex="0" (click)="toggleMenu()">
        <div class="d-flex align-items-center justify-content-center">
          <span class="bi bi-person ml-1 "></span>
        </div>
      </div>

      <div class="iconDiv" style="display: inline-block;" tabindex="0">
        <a href="https://support.covercraft.com/hc/en-us/requests/new" title="Submit a Request">
          <span class="bi bi-headset ml-1 d-lg-none "></span>
        </a>
      </div>

      <cx-garage-icon-component></cx-garage-icon-component>
      <cx-mini-cart tabindex="1"></cx-mini-cart>
    </div>

    <ng-container *ngIf="user$ | async as user;">
      <ng-container *ngIf="isAccountMenuOpen$ | async as state">
        <li *ngIf="state" class="childs flydown_nav" depth="1" columns="1">
          <ng-container *ngFor="let node of accountNodes">
            <cx-generic-link *ngIf="node.url" [url]="node.url">
              {{ node.title }}
            </cx-generic-link>
          </ng-container>
        </li>
      </ng-container>
    </ng-container>

    <li *ngIf="flyout && (node?.children?.length ?? 0) > 1" class="back is-open ">
      <button (click)="customBack()" class="ml-2 " style="height: 60px;">
        <cx-icon [type]="iconType.CARET_LEFT"></cx-icon>
        {{ 'common.back' | cxTranslate }}
      </button>
    </li>

    <ng-container *ngFor="let child of customNode">
      <ng-container *ngTemplateOutlet="nav; context: { node: child, depth: 0 }">
      </ng-container>
    </ng-container>
  </ul>
</nav>

<!-- we generate links in a recursive manner -->
<ng-template #nav let-node="node" let-depth="depth" let-jsClass="jsClass" , let-isHidden="isHidden">

  <!-- level 4  Display-->
  <li *ngIf="node.hasLevel4" class="{{ jsClass }}" [style.display]="isHidden ? 'none' : ''" [ngClass]="{'is-open': depth === 1, 'custom-nav-list': depth === 0}">
    <cx-generic-link *ngIf=" node.url && (!node.children || node.children?.length === 0); else heading" [url]="node.url" (click)="closeIfClickedTheSameLink(node)">
      {{ node.title }}
    </cx-generic-link>

    <ng-template #heading>
      <ng-container *ngIf="flyout && node.children?.length > 0; else title">
        <ng-container *ngIf="node.url" style="width: 100%; height: 35px;">
          <button [attr.tabindex]="depth < 1 ? 0 : -1" [attr.aria-haspopup]="true" [attr.aria-expanded]="true" [attr.aria-label]="node.title" (click)="toggleOpenNew($any($event), trimTitle(node.title))" (mouseenter)="onMouseEnterNew($event, node.title)" (keydown.space)="toggleOpen($any($event))" (keydown.esc)="back()" [ngClass]="{'hide- w-100': !isMobileMenuHidden}">
            <div class="first-level-menu" [ngClass]="{'custom-nav-item': depth === 0, 'level4-custom-item-hidden': !isMobileMenuHidden}">
              <ng-container *ngTemplateOutlet="handlerLink; context: {node, depth}"></ng-container>
            </div>

            <div class="float-right d-lg-none">
              <cx-icon [type]="iconType.CARET_RIGHT" class="pr-3"></cx-icon>
            </div>
          </button>
        </ng-container>

        <ng-container *ngIf="!node.url" style="width: 100%;">
          <button [attr.tabindex]="0" [attr.aria-haspopup]="true" [attr.aria-expanded]="true" [attr.aria-label]="node.title" (keydown.esc)="customBack()" (click)="toggleLevel4Item($any($event))">
            <div class="level4-item-title semi-bold-common-font ">
              {{ node.title }}
            </div>
          </button>
        </ng-container>
      </ng-container>

      <ng-template #title>
        <span [attr.tabindex]="-1">
          {{ node.title }}
        </span>
      </ng-template>
    </ng-template>

    <div class="wrapper customWrapper custom-mobile-wrapper " *ngIf="node.children && node.children.length > 0" (mouseenter)="depth === 0 ? handleSelectedLink(node) : null" [ngClass]="{'level4-wrapper' : depth === 0 && node.hasLevel4}">
      <div class="d-flex wrapper-container row w-100 ">
        <ul class="childs flex-column mb-4 w-100 d-block test-media" [attr.depth]="getTotalDepth(node)" style="width: 100%;" [attr.wrap-after]="node.children.length > wrapAfter ? wrapAfter : null" [attr.columns]="getColumnCount(node.children.length)" [ngClass]="{'level4-custom-menu p-md-0  col-lg-3 ' : depth === 0}">
          <ng-container *ngFor="let child of node.children; let first=first;">
            <ng-container *ngIf="child?.children && child.children.length > 0 && child.children[0]?.children && child.children[0].children.length > 0  ; else elseBlock">

              <li id="level-4-menu-item-{{trimTitle(node.title)}}" class=" btn-block level4-menu-item bold-common-font" [ngClass]="{'selected-level4-button ': child === selectedButton || ((selectedButton == null)) && first, 'border-focus': child === focusedButton }" (click)="toggleLevel4(getCssStyle(child.title), $event, child )">
                <button class="btn-block btn p-0" (focus)="setBackgroundOnFocus(child)" (focusout)="setBackgroundOnFocus(null)">
                  {{child.title}}

                  <div class="float-right d-lg-none">
                    <cx-icon [type]="iconType.CARET_RIGHT" class="pr-3"></cx-icon>
                  </div>
                </button>
              </li>
            </ng-container>

            <ng-template #elseBlock>
              <li>
                <cx-generic-link [url]="child.url" [class]="'level4link d-flex  align-items-center w-100'" (click)="closeIfClickedTheSameLink(child)">
                  <cx-media [container]="child.iconImage" *ngIf="child.iconImage" class="mr-1"> </cx-media>
                   {{child.title}}
                </cx-generic-link>
              </li>
            </ng-template>
          </ng-container>
        </ul>

        <div class="col-lg-9 level4-mobile-list">
          <div class="row">
            <ng-container *ngFor="let child of node.children">
              <ng-container *ngFor="let childChildren of child.children">
                <div class=" m-0 p-0  col-lg-4 {{getCssStyle(child.title)}}">
                  <ul class="childs  d-lg-flex  " [attr.depth]="getTotalDepth(node)" style="display: block;" [style.display]="!isMobileMenuHidden? 'none' : 'block'" [ngClass]="{'level4-custom-item ' : depth === 0, 'level4-custom-item-hidden': !isMobileMenuHidden}" [attr.wrap-after]="node.children.length > wrapAfter ? wrapAfter : null" [attr.columns]="getColumnCount(node.children.length)">
                    <ng-container *ngTemplateOutlet="nav; context: { node: childChildren, depth: depth + 1, jsClass: getCssStyle(child.title), isHidden: true  }">
                    </ng-container>
                  </ul>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </li>

  <!-- Logged in Menu -->
  <li *ngIf="!node.hasLevel4 && node.styleClasses === 'logged-in-menu' " style="height: 100%;">
    <cx-generic-link *ngIf=" node.url && (!node.children || node.children?.length === 0); else heading " [url]="node.url" (click)="closeIfClickedTheSameLink(node)">
      {{ node.title }}
    </cx-generic-link>

    <ng-template #heading>
      <ng-container *ngIf="flyout && node.children?.length > 0;">
        <button [attr.tabindex]="depth < 1 ? 0 : -1" [attr.aria-haspopup]="true" [attr.aria-expanded]="false" [attr.aria-label]="node.title" (click)="closeIfClickedTheSameLink(node)" (click)="toggleOpen($any($event))" (mouseenter)="onMouseEnter($event)" (keydown.space)="toggleOpen($any($event))" (keydown.esc)="back()" style="height: 100%;" class="loggedInButton js-loggedInButton">
          <cx-generic-link *ngIf="node.url" (click)="closeIfClickedTheSameLink(node)" (click)="handleSelectedLink(node)" (mouseenter)="handleSelectedLink(node)" (focus)="handleSelectedLink(node)" (click)="closeIfClickedTheSameLink(node)">
            <div class="loggedInMenu" style="height: 100%; align-items: center;">

              <div class="d-flex">
                <div class="bi bi-person-fill mr-2  loggedInIcon"></div>
                <div class="loggedInTitle">
                  {{ node.title }}
                </div>
              </div>
            </div>
          </cx-generic-link>
        </button>
      </ng-container>
    </ng-template>

    <div class="wrapper loggedWrapper" *ngIf="node.children && node.children.length > 0">
      <div class="ml-1 row border-bottom mr-1 pb-2 d-md-none user-name-display d-lg-flex">
        <div class=" d-flex align-items-center">
          <div class="bi bi-person-fill "></div>
        </div>

        <div style="font-size: 14px; " class="px-2 text-capitalize bold-common-font col-10 ">
          {{userName}}
        </div>
      </div>

      <ul class="childs " [attr.depth]="getTotalDepth(node)" [attr.wrap-after]="node.children.length > wrapAfter ? wrapAfter : null" [attr.columns]="getColumnCount(node.children.length)">
        <ng-container *ngFor="let child of node.children">
          <cx-generic-link *ngIf=" child.url && (!child.children || child.children?.length === 0); " [url]="child.url" (click)="closeIfClickedTheSameLink(child)" class="bold-common-font">
            {{ child.title }}
          </cx-generic-link>
        </ng-container>
      </ul>
    </div>
  </li>


  <!-- Standart -->
  <li *ngIf="!node.hasLevel4 && node.styleClasses !== 'logged-in-menu'" class="{{jsClass}}" [ngClass]="{'custom-nav-list': depth === 0, 'is-open col-lg-3 custom-standart-item': depth === 1}">
    <cx-generic-link *ngIf=" node.url && (!node.children || node.children?.length === 0); else heading " [url]="node.url" (click)="closeIfClickedTheSameLink(node)" [class]="' d-flex  align-items-center w-100'" [ngClass]="{'custom-nav-item ': depth === 0, 'level4link': depth !== 0}">
      <cx-media [container]="node.iconImage" *ngIf="node.iconImage" class=""> </cx-media>

      <div class="" [ngClass]="{'nav-mobile-title ': depth === 0}">
        {{node.title}}
        <div class="nav-border"></div>
      </div>
    </cx-generic-link>

    <ng-template #heading>
      <ng-container *ngIf="flyout && node.children?.length > 0; else title">
        <ng-container *ngIf="node.url">
          <button [attr.tabindex]="depth === 1 || depth ===0 ? 0 : -1" [attr.aria-haspopup]="true" [attr.aria-expanded]="false" [attr.aria-label]="node.title" (click)="toggleOpen($any($event))" (mouseenter)="onMouseEnter($event)" (keydown.space)="toggleOpen($any($event))" (keydown.esc)="back()" [ngClass]="{'custom-nav-item ': depth === 0}">
            <ng-container *ngTemplateOutlet="handlerLink; context: {node, depth}"></ng-container>

            <div class="float-right  d-lg-none">
              <cx-icon [type]="iconType.CARET_RIGHT" class="pr-3"></cx-icon>
            </div>
          </button>
        </ng-container>

        <ng-container *ngIf="!node.url">
          <div class="level4-item-title semi-bold-common-font">
            <button [attr.tabindex]="depth === 1 || depth === 0 ? 0 : -1" [attr.aria-haspopup]="true" class="px-0" [attr.aria-expanded]="false" [attr.aria-label]="node.title" (click)="closeIfClickedTheSameLink(node)" (click)="toggleCustomStandartItem($any($event))" (mouseenter)="onMouseEnter($event)" (keydown.space)="toggleOpen($any($event))" (keydown.esc)="back()" [ngClass]="{'custom-nav-item  ': depth === 0}">
              {{ node.title }}
            </button>
          </div>
        </ng-container>
      </ng-container>

      <ng-template #title>
        <span [attr.tabindex]="-1" hidden>
          {{ node.title }}
        </span>
      </ng-template>
    </ng-template>

    <!-- we add a wrapper to allow for better layout handling in CSS -->
    <div class="wrapper customWrapper " *ngIf="node.children && node.children.length > 0" [ngClass]="{ 'level4-wrapper': depth === 0, 'custom-mobile-wrapper ': depth === 1 }" (mouseenter)="depth === 0 ? handleSelectedLink(node) : null">
      <ul class="childs " [attr.depth]="getTotalDepth(node)" [ngClass]="depth === 0 || depth == 2? ' ':''" [attr.wrap-after]="node.children.length > wrapAfter ? wrapAfter : null" [attr.columns]="getColumnCount(node.children.length)">
        <ng-container *ngFor="let child of node.children">
          <ng-container *ngTemplateOutlet="nav; context: { node: child, depth: depth + 1 }">
          </ng-container>
        </ng-container>
      </ul>
    </div>
  </li>
</ng-template>

<!-- Templates -->

<ng-template #handlerLink let-node="node" let-depth="depth">
  <ng-container *ngIf="node.url && node.url !== '/'; else titleWithoutLinkTemplate">
    <ng-container *ngTemplateOutlet="titleWithLink; context: {node, depth}"></ng-container>
  </ng-container>

  <ng-template #titleWithoutLinkTemplate>
    <ng-container *ngTemplateOutlet="titleWithoutLink; context: {node, depth}"></ng-container>
  </ng-template>
</ng-template>

<ng-template #titleWithLink let-node="node" let-depth="depth">
  <cx-generic-link [url]="node.url">
    <ng-container *ngTemplateOutlet="contentTitle; context: {node, depth}"></ng-container>
  </cx-generic-link>
</ng-template>

<ng-template #titleWithoutLink let-node="node" let-depth="depth">
  <div class="cx-generic-link-fake">
    <div class="anchor-fake">
      <ng-container *ngTemplateOutlet="contentTitle; context: {node, depth}"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #contentTitle let-node="node" let-depth="depth">
  <div class="nav-mobile-title">
    {{ node.title }}
    <div class="nav-border"></div>
  </div>
</ng-template>
