<ng-container *ngIf="fitVehicle$ | async as fv">
  <ng-container *ngIf="fv.fit; else noFit">
    <div class="message-box message-box__success mb-2 d-flex align-items-center">
      <div class="svg-icon svg-icon__rounded svg-icon__outline mr-2">
        <svg-icon iconId="iconCheckMark"></svg-icon>
      </div>
      <div>
        Vehicle Selected:
        <strong class="message-box__highligt">
          {{ fv.vehicle?.name }}
        </strong>
      </div>
    </div>
  </ng-container>
  <ng-template #noFit>
    <div class="message-box message-box__sorry mb-2">
      <div class="message-box__heading">We're sorry</div>
      <div>
        This product is not available for the {{ fv.vehicle?.name }}.  Try another product or submit a <a target="_blank" href="https://support.covercraft.com/hc/en-us/requests/new?tf_22073736828827=car_and_truck&tf_18924042513435=new_pattern_request">new pattern request</a>.
      </div>
    </div>
  </ng-template>
</ng-container>
