<ng-container *ngIf="{
  product: product$| async,
  thumbs: thumbs$ | async
} as data">
  <section class="product-gallery-slides">

    <!-- THUMBNAILS -->
    <aside class="product-gallery-slides__thumbs product-gallery-slides-wrapper"
    (mousedown)="onDragStart($event)"
    (mousemove)="onDragMove($event)"
    (mouseup)="onDragEnd()"
    (mouseleave)="onDragEnd()"
    >
      <div
        class="product-gallery-slides__thumbs product-gallery-slides-container"
        #thumbsContainer
      >
      <div class="product-gallery__representative">
        {{ 'productCarousel.representativeImageShown' | cxTranslate }}
      </div>

        <ng-container *ngFor="let thumb of data.thumbs | slice: 0: (maxGalleryItems > 0 ? maxGalleryItems - 1 : data.thumbs.length); index as i; trackBy:identify">
          <button class="product-gallery-slides__thumbs--item" [ngClass]="{'active': active === i}">
            <cx-media
              (focus)="openImage(thumb, i, 'image')"
              [container]="thumb"
              format="pdpImage"
              tabindex="0">
            </cx-media>
          </button>
        </ng-container>
      </div>

      <!-- Vídeos Button -->
      <button *ngIf="data?.product?.galleryVideos?.length" class="product-gallery-slides__videos" (click)="onOpenGallery(1)">
        <div class="product-gallery-slides__overlay">
          <i class="bi bi-play-btn"></i>
          <div>{{ 'productCarousel.videos' | cxTranslate }}</div>
        </div>
      </button>
    </aside>
  </section>
</ng-container>
