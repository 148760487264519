import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { USER_ACCOUNT_FEATURE_KEY } from "./user-account.state";
import { userAccountReducer } from './user-account.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(USER_ACCOUNT_FEATURE_KEY, userAccountReducer),
  ],
})

export class UserAccountStoreModule {}
