import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { Product, ScriptLoader, ScriptPlacement, WindowRef } from "@spartacus/core";
import { Observable } from "rxjs";
import { CurrentProductService } from "@spartacus/storefront";
import { ActiveCartFacade, OrderEntry } from "@spartacus/cart/base/root";
import { Order } from "@spartacus/order/root/model";
import { DatePipe } from "@angular/common";

/**
 * Wrapper for the Listrak functionalities.
 */
@Injectable({
  providedIn: 'root'
})
export class TurnToService {
  product$: Observable<Product | null> = this.currentProductService.getProduct();
  private _win: any;
  private datePipe: DatePipe
  private scriptLoaded = false;
  private readonly PDP_PAGE_ID = 'pdp-page';

  constructor(private scriptLoader: ScriptLoader,
              private winRef: WindowRef,
              protected currentProductService: CurrentProductService,
              protected activeCartService: ActiveCartFacade,
              @Inject(LOCALE_ID) public locale: string) {
    this.datePipe = new DatePipe(locale);
    if(this.winRef.isBrowser()){
      this._win = window;
    }
  }

  initializePdpWidget(sku: string): void {
    if (this.winRef.isBrowser()) {
      const pageId = this._win.turnToConfig?.pageId;
      if (!!pageId && pageId !== this.PDP_PAGE_ID) {
        // If the page ID is not PDP we need reload the script.
        // We haven't found another way of restore the Page ID.
        location.reload();
      } else {
        if (this.scriptLoaded) {
          this._win.TurnToCmd('set', { sku });
          this._win.TurnToCmd('gallery.set', { skus: [ sku ] });
          this._win.TurnToCmd('initialize');
        } else {
          this.loadPdp(sku);
        }
      }
    }
  }

  private loadPdp(sku: string): void {
    const win = this._win;
    this._win.turnToConfig = {
      locale: 'en_US',
      pageId: this.PDP_PAGE_ID,
      sku: sku,
      gallery: [ sku ],
    };
    win.TurnToCmd =
      win.TurnToCmd ||
      function () {
        ( win.TurnToCmd.q = win.TurnToCmd.q || [] ).push(arguments);
      };
    this.scriptLoader.embedScript({
      src: `https://widgets.turnto.com/v5/widgets/gdtJkBvy7Sh3qtZsite/js/turnto.js`,
      attributes: {
        id: 'turnTo',
        async: true,
      },
      placement: ScriptPlacement.HEAD,
      callback: (_) => {
        this.scriptLoaded = true;
        this.initializePdpWidget(sku);
      },
    });


  }

  public loadOrders(order: Order) {
    const win = this._win;
    if (this.winRef.isBrowser()) {
      win.turnToConfig = {
        locale: 'en_US',
        pageId: 'order-confirmation-page',
      };
      win.TurnToCmd =
        win.TurnToCmd ||
        function () {
          ( win.TurnToCmd.q =
            win.TurnToCmd.q || [] ).push(arguments);
        };

      this.scriptLoader.embedScript({
        src: `https://widgets.turnto.com/v5/widgets/gdtJkBvy7Sh3qtZsite/js/turnto.js`,
        attributes: {
          id: 'turnTo',
          async: true,
        },
        placement: ScriptPlacement.HEAD,
        callback: (_) => {
          this.submitOrders(order);
        },
      });
    }
  }

  public submitOrders(order: Order) {
    if (this.winRef.isBrowser()) {
      const items: OrderEntry[] = order?.entries?.map((entry) => {
        return entry;
      });

      const turnToItems = items?.map((item) => {
        let baseProductName = item.product.name.substring(0, item.product.name.indexOf(" - "));
        return {
          title: baseProductName || item.product?.name,
          url: location.origin + '/covercraft/en/USD' + item.product?.url,
          itemImageUrl: item.product?.images?.PRIMARY?.product?.url,
          sku: item.product?.baseProduct || item.product?.code,
        };
      });

      const orderData = {
        orderId: order?.code,
        email: this.removeTextBeforePipe(order.user?.uid),
        deliveryDate: this.getDeliveryDate(),
        postalCode: order?.deliveryAddress?.postalCode,
        firstName: order.paymentInfo?.billingAddress?.firstName,
        lastName: order.paymentInfo?.billingAddress?.lastName,
        nickName: order.paymentInfo?.billingAddress?.firstName,
        items: turnToItems,
      };

      ( window as any ).TurnToCmd('feed.send', orderData);
    }
  }

  getDeliveryDate() {
    const today = new Date();
    const deliveryDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);
    return this.datePipe.transform(deliveryDate, 'yyyy-MM-dd');
  }

  removeTextBeforePipe(str: string): string {
    if (str.includes('|')) {
      return str.split('|')[1].trim();
    }
    return str.trim();
}

  loadUGCTicker(skus: string[]): void {
      const win = this._win;
      if (this.winRef.isBrowser()) {
        const existingScript = document.querySelector(
                'script[src="https://widgets.turnto.com/v5/widgets/gdtJkBvy7Sh3qtZsite/js/turnto.js"]'
              );

        if (existingScript) {
          console.log('TurnTo script is already loaded. Skipping.');
          return;
        }

        win.turnToConfig = {
          locale: 'en_US',
          pageId: this.PDP_PAGE_ID,
          gallery: {
            skus: skus,
          },
        };
        win.TurnToCmd =
          win.TurnToCmd ||
          function () {
            (win.TurnToCmd.q = win.TurnToCmd.q || []).push(arguments);
          };

        this.scriptLoader.embedScript({
          src: `https://widgets.turnto.com/v5/widgets/gdtJkBvy7Sh3qtZsite/js/turnto.js`,
          attributes: {
            id: 'turnTo',
            async: true,
          },
          placement: ScriptPlacement.HEAD,
          callback: () => {
            console.log('TurnTo script loaded.');
          },
        });
      }
    }
}
