<ng-container [ngSwitch]="getChildType(item)">
  <ng-container *ngSwitchCase="ChildType.PersonalizedEmbroidery">
    <aside class="custom-cart-item-list-row__child__embroidery">
      <div class="row">
        <div class="col-2 cx-image-container">
          <cx-media [container]="item.personalizedEmbroidery?.image" format="product"></cx-media>
        </div>
        <section class="col-10">
          <div class="cx-name">
            <div class="cx-link">
              <h3 class="bold-common-font">{{ 'productConfiguration.personalizedEmbroidery.cartEntryTitle' | cxTranslate }}</h3>
            </div>
          </div>
          <item-price-display [item]="item"></item-price-display>
          <div *ngIf="item.personalizedEmbroidery?.fontCode">
            <span class="bold-common-font">{{ 'productConfiguration.personalizedEmbroidery.styleTitle' | cxTranslate }}:&nbsp;</span>
            <span class="value">{{ item.personalizedEmbroidery?.fontCode }}</span>
          </div>
          <div *ngIf="item.personalizedEmbroidery?.colorName">
            <span class="bold-common-font">{{ 'productConfiguration.personalizedEmbroidery.colorTitle' | cxTranslate }}:&nbsp;</span>
            <span class="value">{{ item.personalizedEmbroidery?.colorName }}</span>
          </div>
          <div *ngIf="item.personalizedEmbroidery?.text">
            <span class="bold-common-font">{{ 'productConfiguration.personalizedEmbroidery.cartEntryLine1' | cxTranslate }}:&nbsp;</span>
            <span class="value">{{ item.personalizedEmbroidery?.text }}</span>
          </div>
          <div *ngIf="item.personalizedEmbroidery?.text2">
            <span class="bold-common-font">{{ 'productConfiguration.personalizedEmbroidery.cartEntryLine2' | cxTranslate }}:&nbsp;</span>
            <span class="value">{{ item.personalizedEmbroidery?.text2 }}</span>
          </div>          
        </section>
      </div>
    </aside>
  </ng-container>
  <ng-container *ngSwitchCase="ChildType.PremiumBinding">
    <aside class="custom-cart-item-list-row__child__embroidery">
      <div class="row">
        <div class="col-2 cx-image-container">
          <cx-media [container]="item.premiumBinding?.image" format="product"></cx-media>
        </div>
        <section class="col-10">
          <div class="cx-name">
            <div class="cx-link">
              <h3 class="bold-common-font">{{ 'productConfiguration.matBinding.cartEntryTitle' | cxTranslate }}</h3>
            </div>
          </div>
          <item-price-display [item]="item"></item-price-display>
          <div *ngIf="item.premiumBinding?.name">
            <span class="bold-common-font">{{ 'productConfiguration.matBinding.premiumColor' | cxTranslate }}:&nbsp;</span>
            <span class="value">{{ item.premiumBinding?.name }}</span>
          </div>
          <div *ngIf="item.premiumBinding?.code">
            <span class="bold-common-font">{{ 'productConfiguration.matBinding.cartEntryCode' | cxTranslate }}:&nbsp;</span>
            <span class="value">{{ item.premiumBinding?.code }}</span>
          </div>          
        </section>
      </div>
    </aside>
  </ng-container>
  <ng-container *ngSwitchCase="ChildType.automotiveLogo">
    <aside class="custom-cart-item-list-row__child__embroidery">
      <div class="row">
        <div class="col-2 cx-image-container">
          <cx-media [container]="item.product?.images?.['PRIMARY']" format="product"></cx-media>
        </div>
        <section class="col-10">
          <div class="cx-name">
            <div class="cx-link">
              <h3 class="bold-common-font">{{ item.product?.name }}</h3>
            </div>
          </div>
          <item-price-display [item]="item"></item-price-display>
          <div *ngIf="item?.automotiveLogo?.product?.code">
            <span class="bold-common-font">{{ 'productConfiguration.matBinding.cartEntryCode' | cxTranslate }}:&nbsp;</span>
            <span class="value">{{ item.automotiveLogo.product.code }}</span>
          </div>          
        </section>
      </div>
    </aside>
  </ng-container>
  <ng-container *ngSwitchCase="ChildType.embroidery">
    <aside class="custom-cart-item-list-row__child__embroidery">
      <div class="row">
        <div class="col-2 cx-image-container">
          <cx-media [container]="item.product?.images?.['PRIMARY']" format="product"></cx-media>
        </div>
        <section class="col-10">
          <div class="cx-name">
            <div class="cx-link">
              <h3 class="bold-common-font">{{ item.product?.name }}</h3>
            </div>
          </div>
          <item-price-display [item]="item"></item-price-display>
          <div *ngIf="item.embroidery?.colorName">
            <span class="bold-common-font">{{ 'productConfiguration.embroidery.colorTitle' | cxTranslate }}:&nbsp;</span>
            <span class="value">{{ item.embroidery?.colorName }}</span>
          </div>
          <div *ngIf="item.embroidery?.fontName">
            <span class="bold-common-font">{{ 'productConfiguration.embroidery.styleTitle' | cxTranslate }}:&nbsp;</span>
            <span class="value">{{ item.embroidery?.fontName }}</span>
          </div>
          <div *ngIf="item.embroidery?.text">
            <span class="bold-common-font">{{ 'productConfiguration.embroidery.text' | cxTranslate }}:&nbsp;</span>
            <span class="value">{{ item.embroidery?.text }}</span>
          </div>
          <ng-container *ngTemplateOutlet="removeButtonTemplate"></ng-container>
        </section>
      </div>
    </aside>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <aside class="custom-cart-item-list-row__child">
      <div class="row">
        <div class="col-2 cx-image-container">
          <cx-media [container]="item.product?.images?.['PRIMARY']" format="product"></cx-media>
        </div>

        <section class="col-10">

          <!-- PRODUCT NAME -->
          <div *ngIf="item.product?.name" class="cx-name">
            <div class="cx-link">
              <h3 class="bold-common-font">{{ item.product?.name }}</h3>
            </div>
          </div>

          <!-- PRICE -->
          <div *ngIf="item.basePrice">
            <div *ngIf="item?.product.priceWithDiscount.value !== item.product.price.value; else noDiscount" class="cx-value mt-2">
              <span class="custom-price bold-common-font">
                {{ item?.product.priceWithDiscount.formattedValue }}
                <span class="bold-common-font custom-price__promotion">Sale</span>
                <span class="custom-price__og-price medium-common-font">{{ item.product.price.formattedValue }}</span>
                <span class="custom-price__discount medium-common-font">save
                  {{ item?.product.discount.formattedValue }} ({{ item?.product.discountPercentage }}% off)</span>
              </span>
            </div>

            <ng-template #noDiscount>
              <div class="cx-value mt-2">
                <span class="custom-price bold-common-font">
                  {{ item?.basePrice.formattedValue }}
                </span>
              </div>
            </ng-template>
          </div>

          <!-- PART NUMBER -->
          <div *ngIf="item.partNumber" class="mt-1">
            <span class="bold-common-font">Part #: </span> {{ item.partNumber }}
          </div>

          <!-- Remove -->
          <ng-container *ngTemplateOutlet="removeButtonTemplate"></ng-container>
        </section>

      </div>
    </aside>
  </ng-container>
</ng-container>

<ng-template #removeButtonTemplate>
  <ng-container *ngIf="!readonly">
    <div class="cx-remove-btn">
      <button
        (click)="removeEntry()"
        [cxAtMessage]="'cartItems.itemRemoved' | cxTranslate" 
        attr.aria-label="{{ 'addToCart.removeFromCart' | cxTranslate }}"
        class="btn btn-tertiary">
        {{ 'common.remove' | cxTranslate }}
      </button>
    </div>
  </ng-container>
</ng-template>