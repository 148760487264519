<ng-container *ngIf="banner$ | async as data">
  <div class="container rotating-banner-container">
    <ng-container *ngIf="data.urlLink">
      <a
        [href]="data.urlLink" class="rotating-banner-link">
        <section [ngStyle]="image$ | async" class="rotating-banner-bg">
          <div class="container">
            <div class="inner-container">
              <div class="content-top">
                <p *ngIf="data.headerOneText" [class]="(data.headerOneColor ?? 'text-primary') + ' bold-common-font word-wrap title'" [innerHTML]="data.headerOneText"></p>
                <p *ngIf="data.subtitleText" [class]="(data.subtitleColor ?? 'text-primary') + ' bold-common-font word-wrap subtitle'" [innerHtml]="data.subtitleText"></p>
                <cx-generic-link
                  [class]="(data.buttonColor ?? 'text-primary') + ' btn font-weight-bolder'"
                  [url]="data.urlLink"
                  *ngIf="data.buttonText">
                  <span [innerHTML]="data.buttonText"></span>
                </cx-generic-link>
              </div>

              <div class="content-bottom">
                <div class="bullets-container d-none d-sm-block">
                  <div class="bullets-section p-3">
                    <div class="row">
                      <div *ngFor="let item of bulletItems" class="col-4 bullet-item">
                        <ng-container *ngIf="item.url">
                          <a [href]="item.url" class="bullet-link">
                            <p [class]="(data.bulletsColor ?? 'text-primary') + ' font-weight-bolder bullet-item__title mb-0'">{{ item.text }}</p>
                            <p [class]="(data.bulletsColor ?? 'text-primary') + ' bullet-item__description mb-0 d-none d-lg-block'">{{ item.description }}</p>
                          </a>
                        </ng-container>
                        <ng-container *ngIf="!item.url">
                          <p [class]="(data.bulletsColor ?? 'text-primary') + ' font-weight-bolder bullet-item__title mb-0'">{{ item.text }}</p>
                          <p [class]="(data.bulletsColor ?? 'text-primary') + ' bullet-item__description mb-0 d-none d-lg-block'">{{ item.description }}</p>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </a>
    </ng-container>
    <ng-container *ngIf="!data.urlLink">
      <section [ngStyle]="image$ | async" class="rotating-banner-bg">
        <div class="container">
          <div class="inner-container">
            <div class="content-top">
              <p *ngIf="data.headerOneText" [class]="(data.headerOneColor ?? 'text-primary') + ' bold-common-font word-wrap title'" [innerHTML]="data.headerOneText"></p>
              <p *ngIf="data.subtitleText" [class]="(data.subtitleColor ?? 'text-primary') + ' bold-common-font word-wrap subtitle'" [innerHtml]="data.subtitleText"></p>
              <cx-generic-link
                [class]="(data.buttonColor ?? 'text-primary') + ' btn font-weight-bolder'"
                [url]="data.urlLink"
                *ngIf="data.buttonText">
                <span [innerHTML]="data.buttonText"></span>
              </cx-generic-link>
            </div>

            <div class="content-bottom">
              <div class="bullets-container d-none d-sm-block">
                <div class="bullets-section p-3">
                  <div class="row">
                    <div *ngFor="let item of bulletItems" class="col-4 bullet-item">
                      <ng-container *ngIf="item.url">
                        <a [href]="item.url" class="bullet-link">
                          <p [class]="(data.bulletsColor ?? 'text-primary') + ' font-weight-bolder bullet-item__title mb-0'">{{ item.text }}</p>
                          <p [class]="(data.bulletsColor ?? 'text-primary') + ' bullet-item__description mb-0 d-none d-lg-block'">{{ item.description }}</p>
                        </a>
                      </ng-container>
                      <ng-container *ngIf="!item.url">
                        <p [class]="(data.bulletsColor ?? 'text-primary') + ' font-weight-bolder bullet-item__title mb-0'">{{ item.text }}</p>
                        <p [class]="(data.bulletsColor ?? 'text-primary') + ' bullet-item__description mb-0 d-none d-lg-block'">{{ item.description }}</p>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ng-container>
  </div>
</ng-container>
