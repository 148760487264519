<div class="custom-product-card">
  <!-- <wishlist-button></wishlist-button> -->

  <div class="cx-product-image-container ">
    <a [href]="url | sanitizeProductUrl" tabindex="-1">
      <div *ngIf="{ primaryImage: primaryImage$ |  async, hoverImage: hoverImage$ | async } as images" class="cx-product-image">
        <cx-custom-media
          [alt]="product.summary ?? ''"
          [container]="images.primaryImage"
          [height]="300"
          [loading]="ImageLoadingStrategy.LAZY"
          [width]="300"
          fetchPriority="high"
          format="product">
        </cx-custom-media>
        <ng-container *ngIf="isHoverableDevice">
          <cx-custom-media
            [alt]="product.summary ?? product.name"
            [container]="images.hoverImage"
            [height]="300"
            [loading]="ImageLoadingStrategy.LAZY"
            [width]="300"
            class="hover-image"
            fetchPriority="low">
          </cx-custom-media>
        </ng-container>
      </div>
    </a>
  </div>

  <div class="cx-product-details">
    <!-- Variants -->
    <cx-product-variant-color (currentVariant)="handlerVariant($event)" [product]=product></cx-product-variant-color>
    <div class="cx-item-title">
      <!-- Brand Name -->
      <span class="cx-product-brand">{{ product.brand }}</span>

      <!-- Link + Product Name -->
      <a [innerHTML]="product.nameHtml || product.name" [href]="url | sanitizeProductUrl"
         class="cx-product-name">
      </a>

      <!-- Rating -->
      <div class="cx-product-rating">
        <cx-star-rating [rating]="product?.ratingReview ? product?.ratingReview : 0"></cx-star-rating>
        <span *ngIf="product.ratingReview" class="rating-count">({{ product.ratingCount }})</span>
      </div>

      <!-- Craft Name : Only if the product fits in the active vehicle -->
      <div class="cx-craft-name" *ngIf="(isMMPage$ | async) !== true">
        <ng-container *ngIf="craftName$ |async as craftName">
          <i class="icon-check bi bi-check-circle-fill"></i>
          <span class="name">{{ craftName }}</span>
        </ng-container>
      </div>
    </div>

    <cx-product-price [combinedProductState]="combinedProductState$ | async"/>

    <!-- Badges -->
    <div class="mt-auto mb-0 w-100">
      <div class="badges-container">
        <ng-container *ngIf="product.badges?.length">
          <cx-product-badges [badges]="product.badges"></cx-product-badges>
        </ng-container>
      </div>
      <!-- Shop Button -->
      <a [href]="url | sanitizeProductUrl" class="btn btn-secondary cx-add-to-cart"
         type="button">
        {{ 'productList.shop' | cxTranslate }}
      </a>
    </div>
  </div>
</div>
