import { Injectable, OnDestroy } from "@angular/core";
import { ProductListComponentService, SearchCriteria, ViewConfig } from "@spartacus/storefront";
import { combineLatest, Observable, Subscription } from "rxjs";
import { ActivatedRouterStateSnapshot, CmsService, CurrencyService, isNotNullable, LanguageService, ProductSearchService, RouterState, RoutingService } from "@spartacus/core";
import { debounceTime, distinctUntilChanged, filter, map, take, tap, withLatestFrom } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { GarageService } from "../../../ymm/core/facade/garage.service";

@Injectable({ providedIn: 'root' })
export class CustomProductListComponentService extends ProductListComponentService implements OnDestroy {
  private subscriptions = new Subscription();

  constructor(
    productSearchService: ProductSearchService,
    routing: RoutingService,
    activatedRoute: ActivatedRoute,
    currencyService: CurrencyService,
    languageService: LanguageService,
    router: Router,
    config: ViewConfig,
    private cmsService: CmsService,
    private garageService: GarageService
  ) {
    super( productSearchService, routing, activatedRoute, currencyService, languageService, router, config);
    this.watchingGarageChanges();
    this.initializeGarage();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  refreshSearch(): void {
    this.routing.getRouterState()
      .pipe(
        map(routerState => (routerState as RouterState).state),
        take(1)
      )
      .subscribe(state => {
        const criteria = this.customGetCriteriaFromRoute(state);
        this.search(criteria);
      });
  }

  override getPageItems(pageNumber: number): void {
    this.routing.getRouterState()
    .pipe(
      withLatestFrom(this.cmsService.getCurrentPage())
    )
    .subscribe(([route, page]) => {
        const routeCriteria = this.getCriteriaFromRoute( route.state.params, route.state.queryParams );
        const criteria = { ...routeCriteria, currentPage: pageNumber };

        if (page.label === `shop`) {
          if (page.year && page.make && page.model) {
            criteria.ymm = `${page.year}|${page.make}|${page.model}`;
          } else if (page.make && page.model) {
            criteria.ymm = `${page.make}|${page.model}`;
          } else if (page.make) {
            criteria.ymm = `${page.make}`;
          }
        }

      if (route.state.url.includes('/shop/') && route.state.url.replace('/shop/', '').includes('/')) {
        // categories exist in url
        // Extract the path from the URL
        let path = route.state.url.split('?')[0]; // Remove query parameters by splitting at '?'
        let cats = path.replace('/shop/', '').split('/').slice(1); // Remove '/shop/' and split the path

        // Filter out any empty strings
        criteria.lvlOneCategories = cats.filter(cat => cat);
      }

        this.search(criteria);
      })
      .unsubscribe();
  }

  protected override searchByRouting$: Observable<ActivatedRouterStateSnapshot> =
    combineLatest([
      this.routing.getRouterState().pipe(
        distinctUntilChanged((x, y) => {
          // router emits new value also when the anticipated `nextState` changes
          // but we want to perform search only when current url changes
          return x.state.url === y.state.url;
        })
      ),
      ...this.customSiteContext
    ]).pipe(
      debounceTime(0),
      map(([routerState, ..._context]) => (routerState as RouterState).state),
      withLatestFrom(this.cmsService.getCurrentPage()),
      tap(([state, page]) => {
        const criteria = this.customGetCriteriaFromRoute(state);
        if (page.year && page.make && page.model) {
          criteria.ymm = `${page.year}|${page.make}|${page.model}`;
        } else if (page.make && page.model) {
          criteria.ymm = `${page.make}|${page.model}`;
        } else if (page.make) {
          criteria.ymm = `${page.make}`;
        }

        if (state.url.includes('/shop/') && state.url.replace('/shop/', '').includes('/')) {
          // categories exist in url
          // Extract the path from the URL
          let path = state.url.split('?')[0]; // Remove query parameters by splitting at '?'
          let cats = path.replace('/shop/', '').split('/').slice(1); // Remove '/shop/' and split the path

          // Filter out any empty strings
          criteria.lvlOneCategories = cats.filter(cat => cat);
        }

        this.search(criteria);
      }),
      map(([routerState, _]) => routerState)
    );

  private customGetCriteriaFromRoute(state: ActivatedRouterStateSnapshot): SearchCriteria {
    const routeParams = state.params;
    const queryParams = state.queryParams;
    return {
      query: queryParams["query"] || this.getQueryFromRouteParams(routeParams),
      pageSize: queryParams["pageSize"] || this.config.view?.defaultPageSize,
      currentPage: queryParams["currentPage"],
      sortCode: queryParams["sortCode"],
    };
  }


  protected override search(criteria: SearchCriteria): void {
    const currentPage = criteria.currentPage;
    const pageSize = criteria.pageSize;
    const sort = criteria.sortCode;
    const ymm = criteria.ymm;
    const lvlOneCategories : String[] = criteria.lvlOneCategories;
    const obj = Object.assign(
      {},
      currentPage && { currentPage },
      pageSize && { pageSize },
      sort && { sort },
      ymm && { ymm },
      lvlOneCategories && { lvlOneCategories },

    );
    this.productSearchService.search(
      criteria.query,
      // TODO: consider dropping this complex passing of cleaned object
      obj
    );
  }

  private initializeGarage(): void {
    this.cmsService.getCurrentPage()
      .pipe(
        filter(isNotNullable),
        withLatestFrom(this.garageService.getActiveVehicle()),
        take(1)
      )
      .subscribe(([p, v]) => {
        let yearMakeModel;
        if (p.template === 'YMMCategoryPageTemplate') {
          const { year, make, model } = p;
          yearMakeModel = { year, make, model };
          if (!v || (year !== v.ymm.year
            || make !== v.ymm.make
            || model !== v.ymm.model)) {
            if (year) {
              // Only set active vehicle when it's different YMM and year is not empty
              this.garageService.setVehicle(yearMakeModel);
              this.garageService.changeGarageState('ACTIVE');
            }
          }
        }
      });
  }


  watchingGarageChanges(): void {
    const ymmTemplates = [
      'ProductGridPageTemplate',
      'SearchResultsGridPageTemplate',
      'CategoryPageTemplate',
      'YMMCategoryPageTemplate',
      'SearchResultsListPageTemplate'
    ];
    this.subscriptions.add(
      this.garageService.getActiveVehicle()
        .pipe(
          distinctUntilChanged((v1, v2) => {
            return v1?.code === v2?.code
          })
        )
        .pipe(
          withLatestFrom(this.cmsService.getCurrentPage()
            .pipe(filter(isNotNullable)))
        )
        .subscribe(([vehicle, page]) => {
          if (ymmTemplates.some(t => t === page.template)) {
            if (!vehicle) {
              if (page.template !== 'YMMCategoryPageTemplate') {
                this.refreshSearch();
              } else {
                this.routing.go({ cxRoute: 'home' });
              }
            } else {
              if (page.template === 'YMMCategoryPageTemplate') {
                /*this.routing.go({
                  cxRoute: 'ymm', params: { ymm: vehicle.path }
                });*/
              } else {
                this.refreshSearch();
              }
            }
          }
        })
    );
  }

  /**
   * This method is private in Spartacus, so we
   * had to bring it here as well.
   * @private
   */
  private get customSiteContext(): Observable<string>[] {
    return [this.languageService.getActive(), this.currencyService.getActive()];
  }
}
