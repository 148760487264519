import { ChangeDetectionStrategy, Component, HostListener, inject, AfterViewInit, Renderer2, ElementRef } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { CmsComponentData } from "@spartacus/storefront";
import { CmsParagraphComponent } from "@spartacus/core";
import { Router } from "@angular/router";
import { ViewportScroller } from "@angular/common";
import { TurnToService } from 'src/app/spartacus/features/turnto/core/turnto.service';

@Component({
  selector: 'cx-paragraph',
  templateUrl: './custom-paragraph.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomParagraphComponent {
  protected sanitizer = inject(DomSanitizer);
  private skus = ['PFC-PFCO', 'CGT-GTEN', 'CGT-GTCA', 'PFC-KRCO', 'CGT-GTLT', 'CGT-GTSM'];

  @HostListener('click', [ '$event' ])
  public handleClick(event: Event): void {
    if (event.target instanceof HTMLAnchorElement) {
      const element = event.target as HTMLAnchorElement;
      const href = element?.getAttribute('href');

      if (href.indexOf('#') >= 0) {
        const urlParts = href.split('#');
        if (!urlParts[ 0 ] || urlParts[ 0 ] === element.ownerDocument.URL) {
          event.preventDefault();
          this.viewportScroller.scrollToAnchor(urlParts[ urlParts.length - 1 ]);
          return;
        }
      }

      const documentHost = element.ownerDocument.URL
        .split('://')[ 1 ]
        .split('/')[ 0 ];

      if (href && documentHost === element.host) {
        event.preventDefault();
        // the angular router requires the path vs. the full URL
        this.router.navigateByUrl(element.pathname);
      }
    }
  }

  constructor(public component: CmsComponentData<CmsParagraphComponent>,
              protected router: Router,
              private viewportScroller: ViewportScroller,
              private renderer: Renderer2,
              private turnToService: TurnToService,
              private el: ElementRef) {
  }

  public bypassSecurityTrustHtml(html: string = ''): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  ngAfterViewInit(): void {
      const ttGalleryDiv = this.el.nativeElement.querySelector('.tt-gallery-row');
      if (ttGalleryDiv) {
        if (!ttGalleryDiv.hasAttribute('id')) {
          this.renderer.setAttribute(ttGalleryDiv, 'id', 'tt-gallery-row');
          console.log('Added id="tt-gallery-row" to the div with class="tt-gallery-row".');
        } else {
          console.log('Element with id="tt-gallery-row" already exists. Skipping id assignment.');
          return;
        }
        this.turnToService.loadUGCTicker(this.skus);
      } else {
        console.warn('No element with class "tt-gallery-row" found. Skipping TurnTo script loading.');
      }
    }
}
