import { ChangeDetectionStrategy, Component, HostBinding, OnInit } from '@angular/core';
import { BannerCarouselComponent, BreakpointService, CmsComponentData } from '@spartacus/storefront';
import { map, tap } from 'rxjs/operators';
import { ToolsService } from 'src/app/components/tools.service';
import { breakpointMediaMapKey } from 'src/app/components/utils/cms-utils';
import { environment } from 'src/environments/environment';
import { BulletsItems } from '../custom-banner-carousel.model';
import { fillBannersLink, getBannerLink } from "../../../../../../tools/tools";

@Component({
  selector: 'cx-custom-banner-carousel-item',
  templateUrl: 'custom-banner-carousel-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomBannerCarouselItemComponent {
  banner!: any;
  bulletItems!: BulletsItems[];
  banner$ = this.componentData.data$.pipe(
    tap((data)=>{
      data.urlLink = getBannerLink(data);
      this.banner = data
      this.bulletItems = [
        {text: data.bullet1Text, description: data.bullet1Description, url: data.bullet1Url},
        {text: data.bullet2Text, description: data.bullet2Description, url: data.bullet2Url},
        {text: data.bullet3Text, description: data.bullet3Description, url: data.bullet3Url},
      ]
      return data
    })
  );

  image$ = this.breakpointService.breakpoint$.pipe(
    map(breakpoint => {
      if (!this.banner?.media) {
        return {};
      }
      const mediaKey = breakpointMediaMapKey.get(breakpoint) ?? 'desktop';
      return { 'background-image': `url(${ environment.baseUrl }${ this.banner?.media[ mediaKey ]?.url })` };
    })
  );

  constructor(
    private componentData: CmsComponentData<any>,
    private breakpointService: BreakpointService,
    private toolsService: ToolsService
  ) {}


}
