import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { debounceTime, distinctUntilChanged, filter, map, shareReplay, startWith, withLatestFrom } from "rxjs/operators";
import { combineLatest, Observable, Subscription } from "rxjs";
import { AbstractControl, FormBuilder, FormControl, ValidatorFn, Validators } from "@angular/forms";
import { isNotNullable } from "@spartacus/core";
import { Embroidery, EmbroideryFont, ProductConfigStepType } from "../../core/models/product-configuration.models";
import { EmbroiderySelection } from "../../core/store/product-configuration.state";
import { ProductConfigurationService } from "../../core/facade/product-configuration.service";
import { STEP_INDEX } from "../../dialog/product-config-dialog/product-config-dialog.component";
import { ProductConfiguratorStepsService } from "../../core/facade/product-configurator-steps.service";
import { FormStateService } from "src/app/spartacus/features/shared/services/form-state.service";

@Component({
  selector: 'embroidery',
  templateUrl: './embroidery.component.html',
  styleUrls: ['./embroidery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmbroideryComponent implements OnInit, OnDestroy {
  fontControl = new FormControl('', Validators.required);

  private embroideryValidators: ValidatorFn[] = [
    this.embroideryValidator(26, 6),
    Validators.pattern("[\\s0-9a-zA-Z\\'.,&\\-çÇ]*"),
  ];

  embroideryControl = new FormControl('', {
    validators: [
      ...this.embroideryValidators
    ],
  });
  form = this.fb.group({
    embroideryText: this.embroideryControl,
    font: this.fontControl,
  });
  private embroidery: Embroidery;
  embroidery$: Observable<Embroidery> = this.service
    .getConfigurationForCurrentProduct()
    .pipe(
      map(
        (config) =>
          config?.steps?.find(
            (s) => s.stepType === ProductConfigStepType.EMBROIDERY
          ) as Embroidery
      ),
      filter(isNotNullable),
      withLatestFrom(this.service.getCurrentSelection()),
      map(([embroidery, selection]) => {
        const selectedEmbroidery = selection.selections?.find(
          (s) => s.stepType === ProductConfigStepType.EMBROIDERY
        ) as EmbroiderySelection;
        if (!this.embroideryControl.value && !this.fontControl.value) {
          this.embroideryControl.setValue(selectedEmbroidery?.text ?? '');          
        }
        this.embroidery = embroidery;
        return embroidery;
      }),
      shareReplay(1)
    );
  private subscription = new Subscription();
  private readonly defaultFontPanel = {
    url: 'https://www.covercraft.com/images/products/swatches/smono.jpg',
  };
  fontPanel$ = combineLatest([
    this.fontControl.valueChanges.pipe(startWith(this.fontControl.value)),
    this.embroidery$,
  ]).pipe(
    map(([value, embroidery]) => {
      if (this.embroideryControl.value !== '') {
        this.stepsService.setOpenStep(null);
      }
      const font = embroidery?.fonts?.find((f) => f.code === value);
      return font ? font.image : this.defaultFontPanel;
    })
  );
  constructor(
    private service: ProductConfigurationService,
    private fb: FormBuilder,
    protected stepsService: ProductConfiguratorStepsService,
    private formStateService: FormStateService,
    @Inject(STEP_INDEX) public step: number
  ) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    const savedFormValue = this.formStateService.getFormState(ProductConfigStepType.EMBROIDERY);       
    if (savedFormValue) {
      this.form.setValue(savedFormValue);
    }

    this.subscription.add(
      this.form.valueChanges
        .pipe(
          distinctUntilChanged(
            (v1, v2) => JSON.stringify(v1) === JSON.stringify(v2)
          ),
          debounceTime(500)
        )
        .subscribe((value) => {
          if (this.form.valid && this.embroidery) {
            this.formStateService.setFormState(ProductConfigStepType.EMBROIDERY, value);
            const { embroideryText, font } = value;
            this.service.setEmbroidery(
              embroideryText,
              font,
              this.embroidery.colors?.[0] ?? { name: '', code: 'WH' },
              this.embroidery
            );
          } else {
            this.service.clearEmbroidery();
          }
        })
    );

    this.subscription.add(this.fontControl.valueChanges.subscribe(val => {
      if (this.embroideryControl.value && val != '') {
        this.stepsService.setOpenStep(null);
      }
    }));
  }

  private embroideryValidator(
    requiredLength: number,
    maxBlanks: number
  ): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }
      const actualBlanks = control.value.match(/\s/g) ?? '';
      const actualLength = control.value.length;
      return actualLength > requiredLength
        ? { maxlength: { requiredLength, actualLength } }
        : actualBlanks?.length > 6
        ? { maxBlanks: { maxBlanks, actualBlanks } }
        : null;
    };
  }

  toggleFont(font: EmbroideryFont) {
    let unselect = this.fontControl.value === font.code;

    if (unselect) {
      this.fontControl.setValue('');
    } else {   
      this.fontControl.setValue(font.code);
    }

    this.updateEmbroideryControlValidator(!unselect);
  }

  private updateEmbroideryControlValidator(required: boolean) {
    if (required) {
      this.embroideryControl.setValidators([
        Validators.required,
        ...this.embroideryValidators
      ]);
    } else {
      this.embroideryControl.setValidators([
        ...this.embroideryValidators
      ]);
    }
    this.embroideryControl.updateValueAndValidity();
  }

}
