import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SanitizeProductUrlPipe } from './sanitize-product-url.pipe';

@NgModule({
  declarations: [
    SanitizeProductUrlPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SanitizeProductUrlPipe
  ]
})

export class SanitizeProductUrlModule { }
