import { createReducer, on } from '@ngrx/store';
import { toggleAccountMenu, closeAccountMenu } from './user-account.actions';
import { UserAccountState } from './user-account.state';

export const initialState: UserAccountState = {
  isOpen: false
};

export const userAccountReducer = createReducer(
  initialState,
  on(toggleAccountMenu, (state) => ({ ...state, isOpen: !state.isOpen })),  
  on(closeAccountMenu, (state) => ({ ...state, isOpen: false })),
);

export { UserAccountState };

