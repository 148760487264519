import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalMessageComponentModule, KeyboardFocusModule, OutletModule, OutletRefModule, PageLayoutModule, PageSlotModule, SkipLinkModule } from '@spartacus/storefront';
import { LoaderModule } from 'src/app/components/_CUSTOM/loader/loader.module';
import { ToasterModule } from 'src/app/components/_CUSTOM/toaster/toaster.module';
import { CustomStoreFrontComponent } from './custom-store-front/custom-store-front.component';
import { TemplatesModule } from './templates/templates.module';
import { CustomFooterComponent } from './custom-footer/custom-footer.component';
import { CustomFooterLatestNewsModule } from './custom-footer-latest-news/custom-footer-latest-news.module';
import { provideConfig } from "@spartacus/core";
import { customLayoutConfig, mediaConfig } from "./config/custom-layout-config";

@NgModule({
  declarations: [
    CustomStoreFrontComponent,
    CustomFooterComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    GlobalMessageComponentModule,
    OutletModule,
    OutletRefModule,
    PageLayoutModule,
    PageSlotModule,
    SkipLinkModule,
    LoaderModule,
    ToasterModule,
    KeyboardFocusModule,
    TemplatesModule,
    CustomFooterLatestNewsModule
  ],
  exports: [
    CustomStoreFrontComponent
  ],
  providers: [
    provideConfig(customLayoutConfig),
    provideConfig(mediaConfig)
  ]
})

export class CustomMainModule { }
