<ng-container *ngIf="embroidery$ | async as embroidery">
<configurator-accordion-item title="Text & lettering Style" [isAnySelected]="embroideryControl.value !== '' && fontControl.value != ''? true: false" [step]="step" [numberOfOptions]="embroidery.fonts.length">
  <div class="embroidery-config">

    <div class="embroidery-config__content">
      <div class="row justify-content-lg-between">
        <section class="col-12 col-md-6 col-lg-5">

          <!-- FORM -->
          <form [formGroup]="form" class="embroidery-form">

            <!-- EMBROIDERY TEXT -->
            <label class="embroidery-form__label" for="embroideryText">
              {{
                embroidery?.priceWithDiscounts?.value
                  ? embroidery?.priceWithDiscounts?.formattedValue
                  : embroidery.price?.formattedValue
              }}
              <span class="sale" *ngIf="embroidery.priceWithDiscounts?.value > 0">Sale</span>
            </label>

            <div class="discount">
              <ng-container *ngIf="embroidery.priceWithDiscounts?.value > 0">
                <span class="was">
                        was
                        <span class="text-line-through">
                          {{embroidery.price?.formattedValue}}
                        </span>
                      </span>
                <span class="save">save
                  {{ embroidery.discount?.formattedValue }} ({{ embroidery.discountPercentage }}% off)
                </span>
              </ng-container>
            </div>

            <input
              class="embroidery-form__field"
              formControlName="embroideryText"
              id="embroideryText"
              maxlength="36"
              name="embroidery"
              type="text">
            <cx-form-errors
              [control]="embroideryControl">
            </cx-form-errors>
            <div class="embroidery-form__info">
              <strong>NOTE: 20 embroidered letters max.</strong>
              , up to 26 including spaces. Numbers and specific punctuation
              marks allowed: periods, commas, ampersand, apostrophes & hyphens.
            </div>

            <!-- FONT -->
            <div class="embroidery-form__fonts m-t-50">
              <h5 class="embroidery-form__heading">
                Choose a font
              </h5>

              <div class="embroidery-form__font-toggle">
                <ng-container *ngFor="let font of embroidery.fonts; index as i">                                    
                  <div class="embroidery-form__font-toggle--container">                 
                    <label [for]="'embroideryFont_' + i"
                           [ngClass]="{ 'selected': fontControl.value === font.code }"
                           (click)="toggleFont(font);$event.preventDefault();">
                      <input
                        [id]="'embroideryFont_' + i"
                        [value]="font.code"
                        formControlName="font"
                        name="font"
                        type="radio">
                      <img
                        [alt]="font.name"
                        [src]="font.fontMedias?.font"
                        class="embroidery-form__font-toggle--img">
                      <config-select-icon [isSelected]="fontControl.value === font.code">
                      </config-select-icon>
                    </label>
                    <div class="embroidery-form__font-toggle--sample">
                      <img [src]="font.fontMedias.sample" alt="">
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </form>

        </section>

        <!-- SAMPLE -->
        <div class="col-12 col-md-6 col-lg-6 pl-4">
          <aside class="embroidery-config__sample">

              <cx-media [container]="embroidery.image"></cx-media>

              <div class="embroidery-config__sample--note">
                <strong>Note</strong>: {{embroidery.note}}
              </div>

          </aside>
        </div>

      </div>
    </div>

  </div>


  <!-- INSTRUCTIONS -->
  <div class="embroidery-config-instructions">
    <div [innerHTML]="embroidery.instructions"
         class="embroidery-config-instructions__inner">
    </div>
  </div>
</configurator-accordion-item>
</ng-container>
