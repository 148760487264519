import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'sanitizeProductUrl' })
export class SanitizeProductUrlPipe implements PipeTransform {

    transform(url: string | null): string { 
        return this.removeSpecialCharacters(url);
    }

    private removeSpecialCharacters(url: string | null): string {    
        if (!url) {
          return '';
        } 
          
        url = decodeURIComponent(url);
  
        let cleanedText = url
            .replace(/[^a-zA-Z0-9-\/]+/g, '-')
            .replace(/--+/g, '-')
            .replace(/-(?=\/[^\/]*$)/, '');        
   
        return cleanedText;       
    }
}
