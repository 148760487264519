import { Component, ElementRef, Input } from '@angular/core';
import { ProductImageZoomViewComponent } from '@spartacus/product/image-zoom/components';
import { ImageGroup } from '@spartacus/core';

@Component({
  selector: 'cx-product-image-zoom-view',
  templateUrl: './product-image-zoom-view.component.html',
  styleUrls: [ './product-image-zoom-view.component.scss' ]
})
export class CustomProductImageZoomViewComponent extends ProductImageZoomViewComponent {

  @Input() container: ImageGroup;

  // 
  // This was moved from Spartacus the because the image zoom is no longer working
  //  the new way to render the images in spartacus is using a <picture> tag 
  override pointerMove(event: MouseEvent): void {
    const boundingRect =
      this.zoomedImage.nativeElement.getBoundingClientRect() as DOMRect;
    
    //Ths is the line from Spartacus and after the upgrade to 2211.32 this will return a comment instead of the img tag
    //const imageElement = this.zoomedImage.nativeElement.firstChild;

    //new code to get the img tag
    const imageElementList = this.zoomedImage.nativeElement.children;
    const imageElementRoot = imageElementList[0];
    const imageElement = imageElementRoot.getElementsByTagName('img')[0];

    const { positionX, positionY } = this.calculatePointerMovePosition(
      this.zoomedImage,
      event.clientX,
      event.clientY
    );

    this.moveImage(positionX, positionY, boundingRect, imageElement);
  }

}
