export const product = {
  productDetails: {
    id: 'ID',
    quantity: 'Qty',
    productDetails: 'Product Details',
    specification: 'Specs',
    reviews: 'Reviews',
    shipping: 'Shipping',
    share: 'Share',
    showReviews: 'Show reviews',
    noReviews: 'No reviews yet',
    productPrice: 'Product price',
    noProductImage: 'No image available, {{ product }}',
    priceFrom: 'From',
    priceSale: 'Sale',
    priceWas: 'was',
    priceSave: 'save {{value}} ({{percentage}}% off)',
  },
  productList: {
    filterBy: {
      label: 'Sort & Filters',
      action: 'Filter by',
    },
    activeFilter:
      '{{filter}} filter, clicking on this button will remove the filter',
    appliedFilter: 'Selected filters',
    showLess: 'Show less...',
    showMore: 'Show more...',
    sortBy: 'Sort by',
    sortResults: 'Sort results',
    backToTopBtn: 'BACK TO TOP',
    showMoreBtn: 'LOAD {{number}} MORE PRODUCTS',
    productSearchPagination: 'Product search pagination',
    productListResults: 'Product Results List',
    filters: 'Filters',
    paginationInfoForResult: '{{start}}-{{end}} of {{total}} for {{search}}',
    paginationInfo: '{{start}}-{{end}} of {{total}} results',
    paginationForResult: 'for "{{search}}"',
    shop: 'Shop'
  },
  productFacetNavigation: {
    filterBy: {
      label: 'Filter by',
      action: 'Filter by',
      facet: 'Filter results by Facets',
      name: 'Filter by {{name}}',
    },
    appliedFilter: 'Selected filters',
    showLess: 'Show less...',
    showMore: 'Show more...',
    sortBy: 'Sort by',
    ariaLabelShowLess:
      'Show Less, button, clicking on this button will reduce options for the active group',
    ariaLabelShowMore:
      'Show more, button, clicking on this button will show all options for the active group',
    ariaLabelItemsAvailable: '{{name}}, {{state}} {{count}} item available',
    ariaLabelItemsAvailable_other:
      '{{name}}, {{state}} {{count}} items available',
    decreaseOptionsVisibility:
      'Options were hidden from the active group, tab backward to read them or forward for the next group',
    increaseOptionsVisibility:
      'More options were added to the active group, tab backward to read them or forward for the next group',
    only: 'Only',
    andUp: '& up',
  },
  productSummary: {
    id: 'ID',
    showReviews: 'Show reviews',
    showReviewsDetailed:
      'Show {{count}} reviews, Rated {{rating}} out of 5 stars',
    share: 'Share',
    newItemPrice: 'New item price',
    writeAReview: 'Write A Review  |  Q&A',
    writeAReviewDeconstructed: {
      review: 'Write A Review  |  ',
      qa: 'Q&A',
    },
    startAtWith: 'Starting at {{value}}/month with',
    customMade: 'In Stock & Custom Made-to-Order',
    universal: 'In Stock',
    shipWithinBusinessDays: 'Estimated to ship in {{days}} days',
    leadtimeModalTitle: 'Leadtime Details',
    leadtimeModalNote: 'This is an estimated timeframe which starts after the receipt of your order. Actual time before shipping may vary based our current production. This estimate does not include shipping transit time, which will be determined by the shipping method selected.'
  },
  productReview: {
    overallRating: 'Overall Rating',
    reviewTitle: 'Review Title',
    writeYourComments: 'Write your comments',
    rating: 'Rating',
    ratingRequired: 'Product rating, required',
    addRate: 'Add rate: {{count}} star',
    addRate_other: 'Add rate: {{count}} stars',
    reviewerName: 'Reviewer name (optional)',
    writeReview: 'Write a Review',
    more: 'Show More Reviews',
    less: 'Show Less Reviews',
    thankYouForReview:
      'Thank you for the review! Note that reviews may require review before appearing here.',
    postReviewFail:
      'Something went wrong while posting your review. Please try again later.',
  },
  productCarousel: {
    carouselLabel: 'Carousel, {{title}}',
    representativeImageShown: 'Representative Image Shown',
    images: 'Images',
    videos: 'Videos',
    customerImages: 'Customer Images',
    viewAll: 'View All',
  },
  productVariantColor: {
    physicalColorMessage: 'The physical color of the product may vary from the online photo',
    color: 'Color',
    colorStep: 'STEP 1: CHOOSE COLOR - '
  },
  productVariant: {
    select: 'Select',
  },
  productConfiguration: {
    matStyle: {
      title: "MAT STYLE"
    },
    matBinding: {
      title: "MAT BINDING",
      standardBtn: 'STANDARD BINDING',
      standardCost: '(Standard mat binding at no additional cost)',
      standardFree: '$0',
      premiumBtn: 'PREMIUM BINDING ',
      premiumCost: '(Premium mat binding at an additional cost per mat style)',
      cartEntryTitle: 'Premium Binding',
      premiumColor: 'Binding Color',
      cartEntryCode: 'Part #'
    },
    automotiveLogo: {
      title: 'LOGO',
      step2Instruction: 'STEP 2: SELECT LOGO',
      cartEntryTitle: 'Logo'
    },
    applyAutomotiveLogo: {
      title: 'APPLY LOGO',
      subTitle: 'Step 1: Apply Logo'
    },
    embroidery: {
      colorTitle: 'Lettering Color',
      styleTitle: 'Lettering Style',     
      cartEntryTitle: 'Embroidery',
      text: 'Text',
    },
    personalizeMats: {
      title: 'PERSONALIZE MATS',
      instruction: 'STEP 1: SELECT STYLE',
      logoBtn: 'Logo',
      embroideryBtn: 'Embroidery',
      plainBtn: 'Plain Mats'
    },
    personalizedEmbroidery: {
      colorTitle: 'Lettering Color',
      step2Instruction: 'STEP 2: SELECT LETTERING COLOR',
      styleTitle: 'Lettering Style',
      step3Instruction: 'STEP 3: SELECT LETTERING STYLE',
      perLine: 'per line',
      line1Placeholder: 'REQUIRED 1ST LINE OF LETTERING',
      line2Placeholder: 'OPTIONAL 2ND LINE OF LETTERING',
      textInstruction: 'Maximum 10 embroidered letters including spaces. No vehicle makes or models are allowed.',
      applyTitle: 'APPLY EMBROIDERY',
      cartEntryTitle: 'Embroidery',
      cartEntryLine1: 'Line 1',
      cartEntryLine2: 'Line 2'
    }
  },
  addToCart: {
    itemsAddedToYourCart: 'Item(s) added to your cart',
    itemsIncrementedInYourCart:
      'This item was already in your cart. The quantity was updated.',
    items: 'items',
    updatingCart: 'Updating cart...',
    addToCart: 'Add to cart',
    confirmvehicledetails: 'Confirm Vehicle Details',
    viewCart: 'view cart',
    proceedToCheckout: 'proceed to checkout',
    quantity: 'Qty',
    outOfStock: 'Out of stock',
    inStock: 'In stock',
    selectStyleAndSize: 'Select style and size to check stock',
    removeFromCart: 'Remove Product from Cart',
    closeModal: 'Close Modal',
    buyItAgain: 'Buy It Again',
    addToActiveCart: 'Add To Active Cart',
    disableReturn: 'This item cannot be returned or exchanged',
    details: 'details',
    freeShippingOnOrders: 'Free Shipping on Orders over {{value}}',
  },
  TabPanelContainer: {
    tabs: {
      ProductDetailsTabComponent: 'Product Details',
      ProductDescriptionTabComponent: 'Description',
      ProductKeyFeaturesTabComponent: 'Key Features',
      ProductInstallationTabComponent: 'Installation',
      ProductSpecsTabComponent: 'Specs',
      ProductReviewsTabComponent: 'Reviews',
      ReviewTurnToFlexCmsComponent: 'Reviews',
      deliveryTab: 'Shipping',
      SparePartsTabComponent: ' Spare Parts',
      ProductQATabComponent: 'Q&A',
      QATurnToFlexCmsComponent: 'Q&A',
    },
    tabPanelContainerRegion: 'Tab group with more product details',
  },
  addToWishList: {
    add: 'Add to Wish List',
    remove: 'Remove from Wish List',
    anonymous: 'Sign in to add to wish list',
    addedToWishList: 'Product added to wish list',
    removedFromWishList: 'Product removed from wish list',
  },
  stockNotification: {
    notifyMe: 'NOTIFY ME',
    stopNotify: 'STOP NOTIFICATION',
    getNotify: 'Get notified when this product is available.',
    getNotifySuffix: 'to get notified when this product is available.',
    activateChannelsPrefix: 'To be notified you need to activate the ',
    channelsLink: 'Notification Channels',
    activateChannelsSuffix: '.',
    notified: 'You will be notified when this product is back in stock.',
    getNotified: 'Get notified when this product is back in stock.',
    unsubscribeSuccess: 'You will not receive back-in-stock notification for this product.',
    subscriptionDialog: {
      header: 'Out of stock subscription',
      notifiedPrefix: 'You will be notified on:',
      notifiedSuffix: 'as soon as this product is back in stock.',
      manageChannelsPrefix: 'Manage your preferred notification channels on the ',
      manageChannelsLink: 'Notification Preference',
      manageChannelsSuffix: ' page.',
      manageSubscriptionsPrefix: 'You can manage your subscriptions on ',
      manageSubscriptionsLink: 'My Interests',
      manageSubscriptionsSuffix: ' page.',
      okBtn: 'OK',
      subscribing: 'Subscribing you to Out of Stock notifications for this product',
    },
  },
  itemCounter: {
    removeOne: 'Remove one',
    addOneMore: 'Add one more',
    quantity: 'Quantity',
  },
  productView: {
    gridView: 'Select to change to Grid View',
    listView: 'Select to change to List View',
  },
};
