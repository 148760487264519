<!-- Toggle button : Only Mobile -->
<button (click)="toggleForm()" class="ymm-form-btn" type="button">
  <span>{{ 'ymm.garage.protectMy' | cxTranslate }}</span>
  <i class="bi bi-chevron-right"></i>
</button>

<!-- YMM Form -->
<form [formGroup]="form"
      [ngClass]="{ 'ymm-form__open': isOpen }"
      class="ymm-form">

  <div class="ymm-form__inner">

    <!-- HEADING -->
    <div class="ymm-form__heading">

      <ng-container *ngIf="showHeading">
        <div [innerHTML]="headingTranslationKey | cxTranslate"></div>
      </ng-container>

      <!-- Icon close : Only Mobile -->
      <button
        (click)="toggleForm()"
        class="ymm-form__heading--close"
        type="button">
        <svg-icon iconId="iconClose"></svg-icon>
      </button>

    </div>

    <div class="ymm-form__form-container">

      <!-- Subheading : Only Mobile -->
      <div class="ymm-form__subheading">
        {{ 'ymm.garage.subheading' | cxTranslate }}
      </div>

      <!-- YEAR -->
      <div class="ymm-form__field ymm-form__field--year">
        <ng-container *ngIf="years$ | async as years">
          <label class="label-content" for="ymmYear">
            {{ 'ymm.year' | cxTranslate }}
          </label>
          <select
            [formControl]="yearControl"
            class="form-control"
            name="year"
            id="ymmYear">
            <option [ngValue]="null" hidden>
              {{ 'ymm.year' | cxTranslate }}
            </option>
            <option *ngFor="let year of years" [ngValue]="year">
              {{ year }}
            </option>
          </select>
        </ng-container>
      </div>

      <!-- MAKE -->
      <div class="ymm-form__field ymm-form__field--make">
        <label class="label-content" for="ymmMake">{{ 'ymm.make' | cxTranslate }}</label>
        <select
          [formControl]="makeControl"
          (change)="onMakeChange($event)"
          class="form-control"
          name="make"
          id="ymmMake">
          <option [ngValue]="null" hidden>
            {{ 'ymm.make' | cxTranslate }}
          </option>
          <ng-container *ngIf="makes$ | async as makes">
            <option *ngFor="let make of makes" [value]="make">
              {{ make }}
            </option>
          </ng-container>
        </select>
      </div>

      <!-- MODEL -->
      <div class="ymm-form__field ymm-form__field--model">
        <label class="label-content" for="ymmModel"> {{ 'ymm.model' | cxTranslate }}</label>
        <select
          [formControl]="modelControl"
          (change)="onModelChange($event)"
          class="form-control"
          name="model"
          id="ymmModel">
          <option [ngValue]="null" hidden>
            {{ 'ymm.model' | cxTranslate }}
          </option>
          <ng-container *ngIf="models$ | async as models">
            <option *ngFor="let model of models" [value]="model">
              {{ model }}
            </option>
          </ng-container>
        </select>
      </div>

      <!-- ACTIONS -->
      <div class="ymm-form__actions">
        <button
          (click)="shop()"
          [disabled]="form.invalid"
          class="btn-primary"
          type="submit">
          {{ 'ymm.garage.shop' | cxTranslate }}
        </button>
      </div>
    </div>

    <!-- Help Text : Only Mobile -->
    <div class="ymm-form__help" [ngClass]="{'d-none': hideHelpText}">
      {{ 'ymm.garage.helpText' | cxTranslate }}
    </div>

  </div>
</form>
