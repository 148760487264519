<div class="cx-product-intro " *ngIf="product$ | async as product">

  <div class="brand bold-common-font">{{ product.brand }}</div>

  <h1 class="name bold-common-font">{{ product.name }}</h1>

  <div class="rating">
    <div class="stars" style="cursor: pointer;">
      <cx-star-rating [rating]="product.ratingReview || 0" (click)="showReviews()"></cx-star-rating>
      <span class="count">({{ product.ratingCount || 0 }})</span>
      <span class="bi bi-chevron-down chevron"></span>
    </div>

    <div class="js-product-review-selector">
      <button *ngIf="areReviewsAvailable$ | async"
        class="btn btn-link review medium-common-font"
        [attr.aria-label]="'productSummary.showReviewsDetailed' | cxTranslate: { rating: product.ratingReview | number: '1.0-1', count: product.ratingCount }">
        <span (click)="showReviews()"> {{ 'productSummary.writeAReviewDeconstructed.review' | cxTranslate }} </span>
        <span (click)="showQa()"> {{ 'productSummary.writeAReviewDeconstructed.qa' | cxTranslate }} </span>
      </button>
    </div>
  </div>

  <cx-product-price [combinedProductState]="combinedProductState$ | async"/>

  <div class="divider"></div>
</div>

<!-- Define a template for shared content
<ng-template #sharedContent let-product="product">
  <div class="cx-product-intro ">
    <div class="brand bold-common-font">{{ product.brand }}</div>
    <h1 class="name bold-common-font">{{ product.name }}</h1>

    <div *ngIf="!isSkuPage" class="rating">
      <div class="stars">
        <cx-star-rating [rating]="product.ratingReview || 0" (click)="showReviews()"></cx-star-rating>
        <span class="count">({{ product.ratingCount || 0 }})</span>
        <span class="bi bi-chevron-down chevron"></span>
      </div>

      <div class="js-product-review-selector">
        <button *ngIf="areReviewsAvailable$ | async"
          class="btn btn-link review medium-common-font"
          [attr.aria-label]="'productSummary.showReviewsDetailed' | cxTranslate: { rating: product.ratingReview | number: '1.0-1', count: product.ratingCount }">
          <span (click)="showReviews()"> {{ 'productSummary.writeAReviewDeconstructed.review' | cxTranslate }} </span>
          <span (click)="showQa()"> {{ 'productSummary.writeAReviewDeconstructed.qa' | cxTranslate }} </span>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<!- Main product section with price set to null ->
<ng-container *ngIf="product$ | async as product; else fallback">
  <ng-container *ngTemplateOutlet="sharedContent; context: { product: product }"></ng-container>
  <cx-product-price
    [displayPriceFrom]="displayFrom"
    [price]="null"
    [product]="product">
  </cx-product-price>
  <div class="divider"></div>
</ng-container>

<!- Fallback section with its own observable ->
<ng-template #fallback>
  <ng-container *ngIf="productWithVariants$ | async as product;">
    <ng-container *ngTemplateOutlet="sharedContent; context: { product: product }"></ng-container>
    <cx-product-price
      [displayPriceFrom]="displayFrom"
      [price]="price$ | async"
      [product]="null">
    </cx-product-price>
    <div class="divider"></div>
  </ng-container>
</ng-template>
-->