import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ImageGroup, ImageType } from '@spartacus/core';
import { map } from "rxjs/operators";
import { ProductDetailsService } from "../../core/facade/product-details.service";
import { CustomCurrentProductService } from '../../current-product.service';

@Component({
  selector: 'product-medias-slides',
  templateUrl: './product-medias-slides.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./product-medias-slides.component.scss']
})
export class ProductMediasSlidesComponent implements AfterViewInit {

  @Input() active: number = 0; // Start with the first image active by default
  @Input() maxGalleryItems = 4;
  @Output() changeMedia = new EventEmitter<{ container: ImageGroup, index: number, mediaType: string }>();
  @Output() openGallery = new EventEmitter<number>();

  @ViewChild('thumbsContainer') thumbsContainer: ElementRef; // Reference to the thumbs container

  private isDragging = false;
  private startX = 0;
  private scrollLeft = 0;

  product$ = this.customCurrentProductService.getProduct();
  thumbs$ = this.productDetailsService.activeGallery$.pipe(
    map((images) => images?.[ImageType.GALLERY] as ImageGroup[] ?? [])
  );

  constructor(
    private productDetailsService: ProductDetailsService,
    private customCurrentProductService: CustomCurrentProductService,
    private cdr: ChangeDetectorRef // Inject ChangeDetectorRef
  ) {}

  identify(i: number, item: ImageGroup): number {
    return item?.['thumbnail']?.galleryIndex;
  }

  openImage(container: ImageGroup, index: number, mediaType: string) {
    this.changeMedia.emit({
      container,
      index,
      mediaType
    });
  }

  onOpenGallery(tabIndex = 0) {
    this.openGallery.emit(tabIndex);
  }

  ngAfterViewInit() {
    // Ensure the thumbsContainer reference is not undefined
    if (this.thumbsContainer) {
      this.scrollToActiveThumbnail();
    }
  }

  ngOnChanges() {
    // Ensure the thumbsContainer reference is available before calling the method
    if (this.thumbsContainer) {
      this.scrollToActiveThumbnail();
    }
  }

  scrollToActiveThumbnail() {
    const container = this.thumbsContainer.nativeElement;

    // Get the first thumbnail item
    const firstThumbnail = container.querySelector('.product-gallery-slides__thumbs--item');

    if (!firstThumbnail) {
      console.error('No thumbnails found!');
      return;
    }

    // Get the width of one thumbnail (including any border or padding)
    const thumbnailWidth = firstThumbnail.offsetWidth;

    // Get the margin (left + right) around the thumbnail
    const computedStyle = window.getComputedStyle(firstThumbnail);
    const marginLeft = parseInt(computedStyle.marginLeft, 10) || 0;
    const marginRight = parseInt(computedStyle.marginRight, 10) || 0;
    const margin = marginLeft + marginRight;

    // Add margin to the thumbnail width
    const thumbnailWithSpace = thumbnailWidth + margin;

    // Calculate the target position based on the active index
    let targetPosition = this.active * thumbnailWithSpace;

    // Calculate the maximum scrollable position
    const maxScrollLeft =
      Math.max(0, container.scrollWidth - container.clientWidth);

    // Clamp the target position to ensure it doesn't exceed max scrollable position
    targetPosition = Math.min(targetPosition, maxScrollLeft);

    // Scroll to the calculated position
    container.scrollTo({
      left: targetPosition,
      behavior: 'smooth',
    });
  }

  onDragStart(event: MouseEvent | TouchEvent) {
    this.isDragging = true;
    const pageX = event instanceof TouchEvent ? event.touches[0].pageX : event.pageX;
    this.startX = pageX;
    this.scrollLeft = this.thumbsContainer.nativeElement.scrollLeft;

    event.preventDefault();
  }

  onDragMove(event: MouseEvent | TouchEvent) {
    if (!this.isDragging) return;

    const pageX = event instanceof TouchEvent ? event.touches[0].pageX : event.pageX;
    const walk = pageX - this.startX;

    // Update the `active` index based on drag direction
    if (walk > 50) {
      // Dragging right
      this.moveToPrevious();
      this.isDragging = false;
    } else if (walk < -50) {
      // Dragging left
      this.moveToNext();
      this.isDragging = false;
    }
  }

  onDragEnd() {
    this.isDragging = false;
  }

  moveToPrevious() {
    if (this.active > 0) {
      this.active--;
      this.scrollToActiveThumbnail();
    }
  }

  moveToNext() {
    const container = this.thumbsContainer.nativeElement;
    if (this.active < container.children.length - 2) {
      this.active++;
      this.scrollToActiveThumbnail();
    }
  }


}
