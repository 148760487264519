/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActiveCartFacade } from "@spartacus/cart/base/root";
import { Country, GlobalMessageService, Region, TranslationService, UserAddressService } from '@spartacus/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { AddressFormComponent } from "@spartacus/user/profile/components";
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { tap } from 'rxjs/operators';
import { us_ca_mask_phone } from 'src/app/components/_CUSTOM/form/masks';

@Component({
  selector: 'cx-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CustomAddressFormComponent extends AddressFormComponent implements OnInit {
  us_ca_mask_phone: string = us_ca_mask_phone;
  override cancelBtnLabel = 'back'

  constructor(
    protected override fb: UntypedFormBuilder
    ,
    protected override userAddressService: UserAddressService,
    protected override globalMessageService: GlobalMessageService,
    protected override translation: TranslationService,
    protected override launchDialogService: LaunchDialogService,
    protected activeCartService: ActiveCartFacade, userProfileFacade: UserProfileFacade
  ) {
    super(fb, userAddressService, globalMessageService, translation, launchDialogService, userProfileFacade);

  }


  override addressForm: UntypedFormGroup = this.fb.group({
    country: this.fb.group({
      isocode: ['US', Validators.required],
    }),
    // titleCode: [''],
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    line1: ['', Validators.required],
    line2: [''],
    companyName: [''],
    town: ['', Validators.required],
    region: this.fb.group({
      isocode: [null, Validators.required],
    }),
    postalCode: ['', Validators.required],
    phone: ['', Validators.required],
    // cellphone: '',
    defaultAddress: [false],
  });

  override ngOnInit() {

    // Fetching countries
    this.countries$ = this.userAddressService.getDeliveryCountries().pipe(
      tap((countries: Country[]) => {
        if (Object.keys(countries).length === 0) {
          this.userAddressService.loadDeliveryCountries();
        }
      })
    );

    // Fetching titles
    this.titles$ = this.getTitles();

    if (this.addressData && Object.keys(this.addressData).length !== 0) {
      this.addressForm.patchValue(this.addressData);

      this.countrySelected(this.addressData.country);
      if (this.addressData.region) {
        this.regionSelected(this.addressData.region);
      }
    }

    this.addresses$ = this.userAddressService.getAddresses();

    // Fetching regions
    this.regions$ = this.userAddressService.getRegions('US').pipe(
      tap((regions: Region[]) => {
        const regionControl = this.addressForm.get('region.isocode');
        if (regions && regions.length > 0) {
          regionControl?.enable();
        } else {
          regionControl?.disable();
        }
      })
    )

    this.preventGestureZoom();
  }

  onSelectionChange(event: any): void {
    setTimeout(() => {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    }, 100);
  }

  preventGestureZoom() {
    document.addEventListener('gesturestart', function(e) {
      e.preventDefault();
    });

    document.addEventListener('gesturechange', function(e) {
      e.preventDefault();
    });

    document.addEventListener('gestureend', function(e) {
      e.preventDefault();
    });
  }
}
