<section class="product-line-styles">


  <ng-container *ngIf="line$ | async as line">
    <configurator-accordion-item [title]="line.productLine.userInterfaceDescription" subtitle="COVERS" [isAnySelected]="line.selected !== undefined? true: false" [numberOfOptions]="line.productLine.options.length" [step]="step">

        <article class="configurator-list">

          <button
            (click)="selectItem(opt, line.productLine, opt.basePartNumber !== line.selected?.option?.basePartNumber? true: false )"
            *ngFor="let opt of line.productLine.options"
            class="configurator-card">

            <!-- Media -->
            <div class="configurator-card__media">
              <cx-media [container]="opt.image"></cx-media>
            </div>

            <!-- Price Area -->
            <section class="configurator-card-neck">
              <div class="configurator-card-neck__container">

                <div class="configurator-card-neck__style">
                  Style {{ opt.illustrationCode }}
                </div>

                <div class="configurator-card-neck__price">
                  <div class="configurator-card-neck__price--value">
                    {{
                      opt.priceWithDiscount.value
                        ? opt.priceWithDiscount.formattedValue
                        : opt.price?.formattedValue
                    }}
                  </div>
                  <ng-container *ngIf="opt.discount?.value > 0">
                    <div class="configurator-card-neck__price--sale">
                      Sale
                    </div>
                  </ng-container>

                  <div class="float-right" style="margin-left: auto;">
                    <config-select-icon [isSelected]="opt.basePartNumber === line.selected?.option?.basePartNumber">
                    </config-select-icon>
                  </div>
                </div>

                <!-- Discount -->
              <div class="discount">
                <ng-container *ngIf="opt.discount?.value > 0">
                  <span class="was">
                    {{ 'productDetails.priceWas' | cxTranslate }}

                    <span class="text-line-through">
                      {{opt.price.formattedValue}}
                    </span>
                  </span>

                  <span class="save">
                    {{ 'productDetails.priceSave' | cxTranslate: { value: opt.discount.formattedValue, percentage: opt.discountPercentage } }}
                  </span>
                </ng-container>
              </div>

              </div>
              <!-- <div [ngClass]="{'svg-icon__success': opt.basePartNumber === line.selected?.option?.basePartNumber}"
                  class="svg-icon svg-icon__lg svg-icon__rounded">
                <svg-icon iconId="iconCheckMark"></svg-icon>
              </div> -->
            </section>

            <!-- Features (Bullets) -->
            <section class="configurator-card-feat">
              <h6 class="configurator-card-feat__heading">Features</h6>
              <ul class="configurator-card-feat__list">
                <li *ngFor="let feature of opt.bullets"
                    class="line-card-feat__item">
                  {{ feature }}
                </li>
              </ul>
            </section>

          </button>
        </article>
    </configurator-accordion-item>
  </ng-container>
</section>
