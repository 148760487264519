import { Component, OnDestroy, OnInit } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { CartActions } from '@spartacus/cart/base/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActiveCartFacade } from "@spartacus/cart/base/root";

@Component({
  selector: 'cart-page-template',
  templateUrl: './cart-page-template.component.html',
  styleUrls: [ './cart-page-template.component.scss' ]
})

export class CartPageTemplateComponent implements OnInit, OnDestroy {
  cart$ = this.activeCartService.getActive();
  hasItems$ = this.cart$.pipe(map(cart => cart.totalItems));
  isLoading$ = new BehaviorSubject(true);
  subscriptions = new Subscription();

  constructor(
    private actions$: Actions,
    private activeCartService: ActiveCartFacade
  ) {
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.actions$.pipe(
        ofType(CartActions.LOAD_CART_FAIL, CartActions.LOAD_CART_SUCCESS, CartActions.LOAD_CARTS_SUCCESS)
      ).subscribe(() => this.isLoading$.next(false))
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
