import { createAction, props } from "@ngrx/store";

const TOGGLE_ACCOUNT_MENU = '[USER] Toggle Account Menu';
const CLOSE_ACCOUNT_MENU = '[USER] Close Account Menu';

export const toggleAccountMenu = createAction(
  TOGGLE_ACCOUNT_MENU
);

export const closeAccountMenu = createAction(
  CLOSE_ACCOUNT_MENU
);