<ng-template [cxOutlet]="CartOutlets.ITEM">
  <ng-template [cxOutlet]="CartOutlets.ITEM_CONFIGURATOR_ISSUES"></ng-template>
  <div [ngClass]="compact ? 'cx-compact row' : 'row my-4'">
    <div class="col-2 cx-image-container">
      <a [routerLink]="item.product?.url" tabindex="0">
        <cx-media [container]="item.product?.images?.['PRIMARY']" format="product"></cx-media>
      </a>
    </div>

    <div class="cx-info col-10">
      <section class="cx-info-container row">
        <div [ngClass]="compact ? '' : ' col-md-8 col-lg-8 col-xl-8'">
          <div *ngIf="item.product?.name" class="cx-name">
            <a [routerLink]="item.product?.url" class="cx-link">
              <h3 class="bold-common-font">{{ item.product?.name }}</h3>
            </a>
          </div>

          <div *ngIf="item.basePrice">
            <div *ngIf="item?.product.priceWithDiscount.value !== item.product.price.value; else noDiscount" class="cx-value mt-2">
              <span class="custom-price bold-common-font">
                {{ item?.product.priceWithDiscount.formattedValue }}
                <span class="bold-common-font custom-price__promotion">Sale</span>
                <span class="custom-price__og-price medium-common-font">{{ item.product.price.formattedValue }}</span>
                <span class="custom-price__discount medium-common-font">save
                  {{ item?.product.discount.formattedValue }} ({{ item?.product.discountPercentage }}% off)</span>
              </span>
            </div>

            <ng-template #noDiscount>
              <div class="cx-value mt-2">
                <span class="custom-price bold-common-font">
                  {{ item?.basePrice.formattedValue }}
                </span>
              </div>
            </ng-template>
          </div>

          <div class="mt-2 custom-info">

            <div class="semi-bold-common-font d-flex align-items-center">
              <ng-container *ngIf="item.yearMakeModel && item.product.customMade">
                <i class="bi bi-check-circle-fill mr-1 text-success"></i>
                {{ removePipe(item.yearMakeModel) }} {{item.subModel}}
              </ng-container>
              <ng-container *ngIf="!item.product.customMade">
                <i class="bi bi-check-circle-fill mr-1 text-success"></i>
                Universal Fit
              </ng-container>
            </div>

            <div *ngIf="item?.product?.allVariantsValues?.length > 0">
              <div *ngFor="let variant of item?.product?.allVariantsValues; let i = index">
                <div *ngIf="!variant.hiddenFromUi" class="mt-1">
                  <span class="bold-common-font">{{ variant.qualifier }}: </span> <var>{{ variant.name }}</var>
                </div>
              </div>
            </div>

            <div class="only-in-review">
              <span class="bold-common-font">Qty: </span> <var>{{ item.quantity }}</var>
            </div>

            <div *ngIf="item?.vehicleApp?.length >= 1">
              <div *ngFor="let option of item.vehicleApp; let i = index" class="mt-1 d-flex">
                <span class="bold-common-font text-nowrap mr-1">Option {{ i + 1 }}: </span>
                <span [innerHTML]="option.options"></span>
              </div>
            </div>

            <div *ngIf="item.userInterfaceDescription" class="mt-1">
              <span class="bold-common-font">Mat Style : </span> {{ item.userInterfaceDescription }}
            </div>

            <div *ngIf="item?.automotiveLogo?.product?.code" class="mt-1">
              <span class="bold-common-font">{{ 'productConfiguration.automotiveLogo.cartEntryTitle' | cxTranslate }}:&nbsp;</span> {{item?.automotiveLogo.product.code}}
            </div>

            <div *ngIf="item.partNumber" class="mt-1">
              <span class="bold-common-font">Part #: </span> {{ item.partNumber }}
            </div>
          </div>

          <ng-template [cxOutlet]="CartOutlets.ITEM_DETAILS"></ng-template>
        </div>

        <div [ngClass]="compact ? '' : ' col-sm-12 col-md-12 col-lg-4 col-xl-4'" class="custom-promotions">
          <custom-cart-promotion [promotions]="item.promotions" cssClasses="custom-promotions__box"></custom-cart-promotion>
          <div class="custom-promotions__box bold-common-font">
            <strong class="extra-bold-common-font" *ngIf="item.product.customMade">
              {{
                'productSummary.customMade' | cxTranslate
              }}
            </strong>
            Estimated to ship in {{ item.leadTime }} days
          </div>
        </div>

      </section>

      <div *ngIf="isProductOutOfStock(item.product)" class="cx-availability col-12">
        {{ 'addToCart.outOfStock' | cxTranslate }}
      </div>

      <ng-template [cxOutlet]="CartOutlets.ITEM_BUNDLE_DETAILS"></ng-template>

      <div *ngIf="(!readonly || options.isSaveForLater) && item.updateable" class="cx-actions mt-4 col-12">
        <div [ngClass]="compact ? '' : ' '" class="cx-quantity">
          <div [class.readonly-value]="readonly" class="cx-value ">
            <cx-item-counter [allowZero]="true" [control]="quantityControl" [readonly]=" !item.updateable || readonly || options.isSaveForLater" class="text-center">
            </cx-item-counter>
          </div>
        </div>

        <div class=" cx-remove-btn">
          <button (click)="RemoveCartItem(item)" [cxAtMessage]="'cartItems.itemRemoved' | cxTranslate" attr.aria-label="{{ 'addToCart.removeFromCart' | cxTranslate }}"
                  class="btn btn-tertiary">
            {{ 'common.remove' | cxTranslate }}
          </button>
        </div>
      </div>

      <ng-container *ngFor="let child of item.children">
        <item-child-row [item]="child" [readonly]="readonly" [parentEntry]="item"></item-child-row>
      </ng-container>

    </div>

  </div>


  <ng-template #total>
    <div *ngIf="item.totalPrice" [ngClass]="compact ? '' : ' col-md-3 col-xl-2'" class="cx-total">
      <div class="cx-value">{{ item.totalPrice.formattedValue }}</div>
    </div>
  </ng-template>
</ng-template>
