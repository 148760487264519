import { I18nModule, provideDefaultConfig, CmsConfig, provideConfig } from '@spartacus/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomNavigationUIComponent } from './custom-navigation-ui.component';
import { GenericLinkModule, IconModule, MediaModule } from '@spartacus/storefront';
import { CustomNavigationComponent } from './custom-navigation.component';
import { AnonymousAcountIconModule } from './anonymous-acount-icon/anonymous-acount-icon.module';
import { MiniCartComponent, MiniCartModule } from '@spartacus/cart/base/components/mini-cart';
import { CustomLoginComponent, LoginModule } from 'src/app/spartacus/features/user/account/components/login';
import { GarageIconComponent } from "../../../spartacus/features/ymm/components/garage-icon.component/garage-icon.component";
import { UserAccountStoreModule } from 'src/app/spartacus/features/user/core/store/user-account-store.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    IconModule,
    GenericLinkModule,
    I18nModule,
    MediaModule,
    AnonymousAcountIconModule,
    MiniCartModule,
    GarageIconComponent,
    LoginModule,
    UserAccountStoreModule
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        NavigationComponent: {
          component: CustomNavigationComponent,
        },
      },
    }),
  ],
  declarations: [CustomNavigationComponent, CustomNavigationUIComponent],
  exports: [
    CustomNavigationComponent,
    CustomNavigationUIComponent,
    MiniCartComponent,
    GarageIconComponent,
    CustomLoginComponent],
})
export class CustomNavigationModule {}
